import { serverUrl } from '../../../config';
import { createPoster } from '../../../core/createVideoPoster';
import { VideoIcon, DeleteIcon } from '../../icons/icons';
import BtnTooltip from '../../tooltips/btnTooltip';
import { EdjsTooltips } from '../../tooltips/edjsTooltip';

export default class Video {

  static get toolbox() {
    return {
      icon: VideoIcon,
      title: 'Video',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,

      /**
       * Tool's classes
       */
      container: 'cdx-media-container',
      wrapper: 'cdx-media-wrapper',
      btn: 'cdx-media-btn',
      replaceButton: 'replace-btn',
      removeButton: 'remove-btn',
      blockBtn: 'block-btn',
      blockBtnSmall: 'small',
      blockBtnLarge: 'large',
      largeIcon: 'large-icon',
      xlIcon: 'xl-icon',
      clear: 'clear',
    };

    /**
      * Tool's settings passed from Editor
      *
      * @type {TitleConfig}
      * @private
      */
    this._settings = config;

    this._data = {
      url: data.url || '',
      mid: data.mid || '',
      timestamp: data.timestamp || '',
      poster: data.poster || '',
      listingId: data.listingId || '',
      listingName: data.listingName || '',
      assetId: data.assetId || '',
      fileId: data.fileId || '',
      iceMedia: data?.iceMedia || '',
      external: data?.external || false,
    };

    this._element = this.getElement();
  }

  getElement() {
    let video;

    if (this._data.url) {
      video = document.createElement('video');
      video.classList.add(this._CSS.wrapper);
      video.setAttribute('src', this._data.url);
      video.setAttribute('controls', true);
      video.setAttribute('preload', 'auto');

      if (this._data.poster) {
        if (this._data.external) {
          video.setAttribute('poster', this._data.poster);
        } else {
          video.setAttribute('poster', `${serverUrl}/api/video/thumbnail?mid=${this._data.mid}`);
        }
      }

      // iceportal
      video.setAttribute('data-external', this._data.external);
      video.setAttribute('data-listingId', this._data.listingId);
      video.setAttribute('data-listingName', this._data.listingName);
      video.setAttribute('data-assetId', this._data.assetId);
      video.setAttribute('data-fileId', this._data.fileId);
      video.setAttribute('data-iceMedia', this._data.iceMedia);

      if (this._data.mid) {
        video.setAttribute('data-mid', this._data.mid);
      }
    }

    return video;
  }

  

  render() {
    let container = document.createElement('div');
    container.classList.add(this._CSS.container);
    let video = this._element;

    if (!this.readOnly) {
      let btn = document.createElement('button');
      let btnRemove = document.createElement('button');
      BtnTooltip(btn, EdjsTooltips.video.main);
      BtnTooltip(btnRemove, EdjsTooltips.video.remove);

      if (this._data.url) {
        btn.classList.add(this._CSS.blockBtn, this._CSS.replaceButton, this._CSS.blockBtnSmall, this._CSS.xlIcon);
        btn.innerHTML = `${Video.toolbox.icon}`;
        btnRemove.classList.add(this._CSS.blockBtn, this._CSS.clear, this._CSS.blockBtnSmall, this._CSS.xlIcon);
        btnRemove.innerHTML = `${DeleteIcon}`;
        container.classList.add('has-video');
      } else {
        btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.xlIcon);
        btn.innerHTML = `${Video.toolbox.icon}`;
      }

      btn.onclick = () => {
        const blockIndex = this.api.blocks.getCurrentBlockIndex();
        const block = this.api.blocks.getBlockByIndex(blockIndex);
        this._settings.showMediaLibrary({
          details: block,
          data: this._data,
          bundle: 'video',
        });
      };

      btnRemove.onclick = () => {
        this._data = {
          url: '',
          mid: '',
          timestamp: '',
          poster: '',
          listingId: '',
          listingName: '',
          assetId: '',
          fileId: '',
          iceMedia: '',
          external: false,
        };

        this._element.remove();
        container.classList.remove('has-video')
        btnRemove.remove();
      }

      if (typeof video !== 'undefined') {
        container.appendChild(video);
        container.appendChild(btn);
        container.appendChild(btnRemove);
        container.classList.add('has-video');
      } else {
        // hidden input hack
        // prevents empty blocks being created
        let input = document.createElement('input');
        input.setAttribute('style', 'visibility: hidden; width: 0; height: 0; padding: 0; margin: 0; border: 0; position: absolute');

        container.appendChild(input);
        container.appendChild(btn);
      }
    } else {
      if (typeof video !== 'undefined') {
        container.classList.add('has-video');
        container.appendChild(video);
      }
    }

    return container;
  }

  save() {
    return this._data;
  }
}