import { CloseIcon, ImageIcon, PinIcon } from '../../icons/icons';
import BtnTooltip from '../../tooltips/btnTooltip';
import { EdjsTooltips } from '../../tooltips/edjsTooltip';

/**
 * Build styles
 */
require('./index.css').toString();

export default class Map {

  static get toolbox() {
    return {
      icon: PinIcon,
      title: 'Map',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,

      /**
       * Tool's classes
       */
      container: 'map-container',
      wrapper: 'map',
      newButton: 'map-btn',
      replaceButton: 'map-replace-btn',

      modal: 'map-modal',
      modalInner: 'map-modal-inner',
      input: 'map-input',
      modalImgContainer: 'modal-fallback-img-container',
      modalImg: 'modal-fallback-img',
      fallbackButton: 'map-fallback-btn',
      codeEditor: 'map-editor',
      close: 'map-close',
      blockBtn: 'block-btn',
      blockBtnSmall: 'small',
      blockBtnLarge: 'large',
      largeIcon: 'large-icon',
      xlIcon: 'xl-icon',
      xxlIcon: 'xxl-icon',
      clear: 'clear',
      modalShowBtn: 'map-modal-show-btn',
      editorFont: 'editor-font',
      editorBlockModalBackdrop: 'editor-block-modal-backdrop',
      editorBlockModal: 'editor-block-modal',
      editorBlockModalTitle: 'editor-block-modal-title',
      editorBlockModalLabel: 'editor-block-modal-label',
      editorBlockModalText: 'editor-block-modal-text',
      editorBlockModalInput: 'editor-block-modal-input',
      editorBlockModalImgContainer: 'editor-block-modal-img-container',
      adminFont: 'admin-font',
    };

    /**
      * Tool's settings passed from Editor
      *
      * @type {TitleConfig}
      * @private
      */
    this._settings = config;

    this._data = {
      label: data.label || '',
      latitude: data.latitude || '',
      longitude: data.longitude || '',
      url: data.url || '',
      mid: data.mid || '',
      width: data.width || '',
      height: data.height || '',
      x: data.x || '',
      y: data.y || '',
      maxWidth: data.maxWidth || '',
      maxHeight: data.maxHeight || '',
      zoomLevel: data.zoomLevel || '16'
    };

    this._element = this.getElement();
    this._labelElement = this.labelElement();
    this._latLongElement = this.latLongElement();
    this._zoomLevelElement = this.zoomLevelElement();
    this._toggleElement = this.toggleElement();
    this._modalFallbackElement = this.modalFallbackElement();
    this._modal = this.modalElement();
  }

  updateMap() {
    const tag = this._element;
    const updatedElement = this.getElement();

    setTimeout(() => {
      tag.replaceWith(updatedElement);
      this._element = updatedElement;
    }, 100);
  }

  getElement() {
    const tag = document.createElement('div');
    tag.classList.add(this._CSS.wrapper);

    tag.setAttribute('location-label', this._data.label);
    tag.setAttribute('location-lat', this._data.latitude);
    tag.setAttribute('location-lng', this._data.longitude);

    let html;

    const apiKey = this._settings.googleApiKey;

    if (!apiKey) {
      html = '<div class="d-flex h-100 justify-content-center align-items-center"><b>Map can\'t be displayed - API key error</b></div>';
    } else {
      const pinSrc = `${this._settings.themeDirectory}/dist/images/icons/${this._settings.pin}`;
      const label = encodeURIComponent(this._data.label);
      const params = `key=${apiKey}&lat=${this.trimNextLine(this._data.latitude.trim())}&lng=${this.trimNextLine(this._data.longitude.trim())}&label=${label}&pin=${pinSrc}&mode=edit&zoom=${this._data.zoomLevel}`;
      const src = `/map.html?${params}`;
      console.log('map src', src);
      html = `<iframe src="${src}" />`;
    }

    tag.innerHTML = html;

    return tag;
  }

  labelElement() {
    const label = document.createElement('input');
    label.placeholder = `Location label`;
    label.classList.add(this._CSS.editorBlockModalInput, this._CSS.editorFont);


    label.value = this._data.label || '';

    const that = this;
    label.addEventListener('input', function (event) {
      that._data.label = event.target.value;
    });

    return label;
  }

  latLongElement() {
    const latLong = document.createElement('input');
    latLong.placeholder = `Location coordinates (Lat, Long)`;
    latLong.classList.add(this._CSS.editorBlockModalInput, this._CSS.editorFont);

    latLong.value = (this._data.latitude && this._data.longitude) || '';
    if (this._data.latitude !== '' && this._data.longitude !== '') {
      latLong.value = `${this.trimNextLine(this._data.latitude.trim())}, ${this.trimNextLine(this._data.longitude.trim())}`;
    } else {
      latLong.value = '';
    }

    const that = this;
    latLong.addEventListener('input', function (event) {
      let latLongObj = that.parseLatLong(event.target.value);
      that._data.latitude = latLongObj.latitude;
      that._data.longitude = latLongObj.longitude;
    });

    return latLong;
  }

  zoomLevelElement() {
    const zoomLevel = document.createElement('input');
    zoomLevel.placeholder = `Zoom level(0-18)`;
    zoomLevel.classList.add(this._CSS.editorBlockModalInput, this._CSS.editorFont);
    zoomLevel.type = "number";
    zoomLevel.min = 0;
    zoomLevel.max = 18;

    zoomLevel.value = this._data.zoomLevel || '';

    const that = this;
    zoomLevel.addEventListener('input', function (event) {
      if (event.target.value === '') {
        that._data.zoomLevel = "16";
      }
      else {
        that._data.zoomLevel = event.target.value;
      }
    });

    return zoomLevel;
  }

  modalFallbackElement() {
    const imgContainer = document.createElement('div');
    const btn = document.createElement('button');
    BtnTooltip(btn, EdjsTooltips.map.fallback);

    imgContainer.classList.add(this._CSS.modalImgContainer, this._CSS.editorBlockModalImgContainer);
    btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.xxlIcon, this._CSS.newButton);
    btn.innerHTML = `${ImageIcon}`;
    btn.onclick = () => {
      const blockIndex = this.api.blocks.getCurrentBlockIndex();
      const block = this.api.blocks.getBlockByIndex(blockIndex);
      this._settings.showMediaLibrary({
        details: block,
        data: this._data,
        bundle: 'image',
      });
    };

    if (this._data.url) {
      const fallbackImg = document.createElement('img');
      fallbackImg.setAttribute('src', this._data.url);
      fallbackImg.setAttribute('data-mid', this._data.mid);
      fallbackImg.setAttribute('data-x', this._data.x);
      fallbackImg.setAttribute('data-y', this._data.y);
      fallbackImg.setAttribute('data-width', this._data.width);
      fallbackImg.setAttribute('data-height', this._data.height);
      fallbackImg.setAttribute('data-maxwidth', this._data.maxWidth);
      fallbackImg.setAttribute('data-maxheight', this._data.maxHeight);

      fallbackImg.classList.add(this._CSS.modalImg);
      imgContainer.appendChild(fallbackImg);
    }

    imgContainer.appendChild(btn);

    return imgContainer;
  }

  modalElement() {
    const modal = document.createElement('div');
    const modalBackdrop = document.createElement('div');
    const modalInner = document.createElement('div');

    const row1 = document.createElement('div');
    const row2 = document.createElement('div');
    const row3 = document.createElement('div');
    const row4 = document.createElement('div');
    const row5 = document.createElement('div');
    const row6 = document.createElement('div');
    const col1 = document.createElement('div');
    const col2 = document.createElement('div');
    const col3 = document.createElement('div');
    const col4 = document.createElement('div');
    const col5 = document.createElement('div');
    const col6 = document.createElement('div');
    const col7 = document.createElement('div');
    const col8 = document.createElement('div');
    const col9 = document.createElement('div');
    const col10 = document.createElement('div');
    const col11 = document.createElement('div');
    const col12 = document.createElement('div');
    const labelLabel = document.createElement('label');
    const labelLatLong = document.createElement('label');
    const labelFallback = document.createElement('label');
    const labelZoomLevel = document.createElement('label');
    const modalTitle = document.createElement('h4');

    row1.classList.add('row');
    row2.classList.add('row');
    row3.classList.add('row');
    row4.classList.add('row');
    row5.classList.add('row');
    row6.classList.add('row');
    row5.classList.add('mb-3');
    col1.classList.add('col-3');
    col2.classList.add('col');
    col3.classList.add('col-3');
    col4.classList.add('col');
    col5.classList.add('col-3');
    col6.classList.add('col');
    col7.classList.add('col-3');
    col8.classList.add('col');
    col9.classList.add('col');
    col10.classList.add('col-2');
    col10.classList.add('d-flex');
    col10.classList.add('justify-content-end');
    col11.classList.add('col-3');
    col12.classList.add('col');
    labelLabel.classList.add('map-block-modal-label', this._CSS.editorBlockModalLabel, this._CSS.adminFont);
    labelLatLong.classList.add('map-block-modal-label', this._CSS.editorBlockModalLabel, this._CSS.adminFont);
    labelFallback.classList.add('map-block-modal-label', this._CSS.editorBlockModalLabel, this._CSS.adminFont);
    labelZoomLevel.classList.add('map-block-modal-label', this._CSS.editorBlockModalLabel, this._CSS.adminFont);
    modalTitle.classList.add('map-block-modal-label', this._CSS.editorBlockModalLabel, this._CSS.adminFont);

    labelLabel.innerText = "Location label";
    labelLatLong.innerText = "Lat, Long";
    labelFallback.innerText = "Fallback image";
    labelZoomLevel.innerText = "Zoom level";
    modalTitle.innerText = "Google map latitude & longitude";

    const close = document.createElement('button');
    close.innerHTML = `${CloseIcon}`;
    close.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.clear);
    close.classList.add(this._CSS.close);

    col1.appendChild(labelLabel);
    col3.appendChild(labelLatLong);
    col7.appendChild(labelFallback);
    col11.appendChild(labelZoomLevel);
    col9.appendChild(modalTitle);

    col2.appendChild(this._labelElement);
    col4.appendChild(this._latLongElement);
    col7.appendChild(labelFallback);
    col8.appendChild(this._modalFallbackElement);
    col10.appendChild(close);
    col12.appendChild(this._zoomLevelElement);

    row1.appendChild(col1);
    row1.appendChild(col2);
    row2.appendChild(col3);
    row2.appendChild(col4);
    row3.appendChild(col5);
    row3.appendChild(col6);
    row4.appendChild(col7);
    row4.appendChild(col8);
    row5.appendChild(col9);
    row5.appendChild(col10);
    row6.appendChild(col11);
    row6.appendChild(col12);

    const that = this;

    close.addEventListener('click', function (event) {
      event.preventDefault();
      modal.classList.remove('show');
      that.updateMap();
    });


    modal.classList.add(this._CSS.modal);
    modalBackdrop.classList.add(this._CSS.editorBlockModalBackdrop);
    modal.setAttribute('data-mutation-free', true);
    modalInner.classList.add(this._CSS.modalInner, this._CSS.editorBlockModal);

    modalInner.appendChild(row5);
    modalInner.appendChild(row1);
    modalInner.appendChild(row2);
    modalInner.appendChild(row3);
    modalInner.appendChild(row6);
    modalInner.appendChild(row4);

    modal.appendChild(modalBackdrop);
    modal.appendChild(modalInner);

    return modal;
  }

  showEditor() {
    this._modal.classList.add('show');
  }

  toggleElement() {
    const tag = document.createElement('button');
    tag.classList.add('sidebar-btn');

    const leftIcon = document.createElement('span');
    const rightIcon = document.createElement('span');

    leftIcon.classList.add('material-icons', 'left');
    rightIcon.classList.add('material-icons', 'right');

    leftIcon.innerHTML = 'chevron_left';
    rightIcon.innerHTML = 'chevron_right'

    tag.addEventListener('click', function (event) {
      event.preventDefault();

      this.classList.toggle('active');
    });

    tag.appendChild(leftIcon);
    tag.appendChild(rightIcon);

    return tag;
  }

  parseLatLong(value) {
    const data = { latitude: '', longitude: '' };
    const [latitude, longitude] = this.trimNextLine(value.trim()).split(", ").map(coord => coord.trim());
    if (isNaN(latitude) || isNaN(longitude)) {
      return data;
    } else {
      data.latitude = latitude;
      data.longitude = longitude;
      return data;
    }
  }

  trimNextLine(value) {
    return value.replace(/(<br>|<br \/>|\n|\r|\r\n)/g, '');
  }

  render() {
    let container = document.createElement('div');
    let tag = this._element;
    let btn = document.createElement('button');
    BtnTooltip(btn, EdjsTooltips.map.modal);

    container.classList.add(this._CSS.container);
    btn.classList.add(this._CSS.modalShowBtn)

    if (this._data.label || this._data.latitude || this._data.longitude) {
      btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.largeIcon);
      btn.innerHTML = `${Map.toolbox.icon}`;
    } else {
      btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.largeIcon);
      btn.classList.add(this._CSS.newButton);
      btn.innerHTML = `${Map.toolbox.icon}`;
    }


    btn.onclick = () => {
      this.showEditor();
    }

    container.appendChild(tag);
    // container.appendChild(this._toggleElement);
    container.appendChild(this._modal);
    if (!this.readOnly) {
      container.appendChild(btn);
    }
    return container;
  }

  save() {
    this._data = {
      label: this._labelElement?.value || '',
      latitude: this.trimNextLine(this._data?.latitude.trim()) || '',
      longitude: this.trimNextLine(this._data?.longitude.trim()) || '',
      url: this._data.url || '',
      mid: this._data.mid || '',
      x: this._data.x || '',
      y: this._data.y || '',
      width: this._data.width || '',
      height: this._data.height || '',
      maxWidth: this._data.maxWidth || '',
      maxHeight: this._data.maxHeight || '',
      zoomLevel: this._data.zoomLevel || '',
    }
    return this._data;
  }
}