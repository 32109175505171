import React, { useEffect, useState } from "react";

// Modules
import _ from "lodash";
import Gallery from "react-photo-gallery";

// App
import { serverUrl } from "../config";
import { getOnlineNode } from "../core/getNode";
import { paginator } from "../core/paginator";
import NoData from "./noData";
import { decodeHtmlEntities } from "../utils/helpers";
import BackButton from "./mediaLibrary/backButton";
import IceElement from "./iceportalElement";
import SelectedIce from "./mediaLibrary/selectedIce";
import SelectedIceVideo from "./mediaLibrary/selectedIceVideo";

// UI components
import { SearchOutlined, Close } from "@mui/icons-material";
import {
  Button,
  Autocomplete,
  InputAdornment,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  OutlinedInput,
  Pagination as MuiPagination,
  IconButton,
  Typography,
} from "@mui/material";
import LoadingSpinner from "./loadingSpinner";
import { Carousel, Row, Container, Col } from "react-bootstrap";
import FolderIcon from "@mui/icons-material/FolderOutlined";

const pageSize = 24;
const input_font_size = 14;

const defaultCountry = {
  countryCode: "",
  countryID: 0,
  name: "All Countries",
  phonePrefix: "0",
};

export default function IcePortal(props) {
  const [activeIndex, setActiveIndex] = useState(0);

  const [isListingLoading, setListingLoading] = useState(true);
  const [isListingPaginating, setListingPaging] = useState(false);
  const [isListingError, setListingError] = useState(false);

  const [isMediaLoading, setMediaLoading] = useState(false);
  const [isMediaPaginating, setMediaPaginating] = useState(false);
  const [isMediaError, setMediaError] = useState(false);

  const [totalListingItems, setTotalListingItems] = useState(0);
  const [currentListingPage, setCurrentListingPage] = useState(1);

  const [totalMediaItems, setTotalMediaItems] = useState(0);
  const [currentMediaPage, setCurrentMediaPage] = useState(1);

  const [selectedMedia, setSelectedMedia] = useState(null);
  const [cropApplied, setCropApplied] = useState(false);

  const [listings, setListings] = useState([]);
  const [media, setMedia] = useState([]);
  const [mediaMode, setMediaMode] = useState(false);

  const [chains, setChains] = useState([]);
  const [regions, setRegions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [fileId, setFileId] = useState("");

  const [mediaFileId, setMediaFileId] = useState("");
  const [publicMediaId, setPublicMediaId] = useState("");
  const [privateMediaId, setPrivateMediaId] = useState("");
  const [selectedMediaType, setSelectedMediaType] = useState(
    props.mediaType ? props.mediaType : "PH"
  );
  const mediaTypeFilterDisabled = props.mediaTypeFilterDisabled;

  const [selectedListing, setSelectedListing] = useState(
    props.selectedListingId ? props.selectedListingId : null
  );
  const [selectedListingName, setSelectedListingName] = useState(
    props.selectedListingName ? props.selectedListingName : null
  );

  const [selectedChain, setSelectedChain] = useState("All Chains");
  const [selectedRegion, setSelectedRegion] = useState("All Regions");
  const [selectedCountry, setSelectedCountry] = useState(defaultCountry);
  const [selectedCity, setSelectedCity] = useState("All Cities");

  const [shouldOpenSelected, setShouldOpenSelected] = useState(props.shouldOpenOverride ? false : true);

  // media type
  useEffect(() => {
    if (props.mediaType === 'video') {
      setSelectedMediaType("MVI");
    }

    if (props.mediaType === 'image') {
      setSelectedMediaType("PH");
    }
  }, [props.mediaType]);

  // listings
  useEffect(() => {
    async function getContent() {
      setListingError(false);
      setListingPaging(false);
      setListingLoading(true);

      let path = `/api/iceportal/listings?pageSize=${pageSize}&pageNo=${currentListingPage}`;

      if (selectedChain !== "All Chains") {
        path = `${path}&chainCode=${selectedChain}`;
      }

      if (selectedRegion && selectedRegion !== "All Regions") {
        path = `${path}&regionCode=${selectedRegion}`;
      }

      if (selectedCountry && selectedCountry.name !== "All Countries") {
        path = `${path}&countryCode=${selectedCountry.countryCode}`;
      }

      if (selectedCity && selectedCity !== "All Cities") {
        path = `${path}&city=${selectedRegion}`;
      }

      getOnlineNode(path)
        .then((response) => {
          setListings(response.data.results ? response.data.results : []);
          setTotalListingItems(response.data.count ? response.data.count : 0);
          setListingPaging(false);
          setListingLoading(false);
          setListingError(false);
        })
        .catch((_error) => {
          setListingLoading(false);
          setListingError(true);
        });
    }

    getContent();
  }, [
    currentListingPage,
    selectedChain,
    selectedCity,
    selectedCountry,
    selectedRegion,
  ]);

  // filters: countries, chains and regions
  useEffect(() => {
    let path = `/api/iceportal/filters`;

    if (selectedCountry !== "All Countries") {
      path = `${path}?countryCode=${selectedCountry}`;
    }

    getOnlineNode(path)
      .then((response) => {
        let _regions = [
          {
            regionCode: "All Regions",
            regionID: 0,
            regionName: "All Regions",
            supplierID: 0,
          },
        ];

        let _countries = [defaultCountry];

        let _cities = [
          {
            countryCode: "All Cities",
            countryID: 0,
            name: "All Cities",
            phonePrefix: "0",
          },
        ];

        let _chains = ["All Chains"];

        if (response.data.regions && response.data.regions.length > 0) {
          _regions = _regions.concat(response.data.regions);
        }

        if (response.data.countries && response.data.countries.length > 0) {
          let ctrs = _.orderBy(response.data.countries, ["name"], ["asc"]);

          _countries = _countries.concat(ctrs);
        }

        if (response.data.chains && response.data.chains.length > 0) {
          _chains = _chains.concat(response.data.chains);
        }

        if (response.data.cities && response.data.cities.length > 0) {
          _cities = _cities.concat(response.data.cities);
        }

        _regions = _.orderBy(_regions, ["name"], ["asc"]);

        setRegions(_regions);
        setCountries(_countries);
        setChains(_chains);
        setCities(_cities);
      })
      .catch((_error) => {
        console.log("error: ", _error);
      });
  }, [selectedCountry]);

  useEffect(() => {
    if (fileId !== "") {
      setMediaMode(true);
    }
  }, [fileId]);

  useEffect(() => {
    if (props.selectedIceMedia) {
      setSelectedMedia(props.selectedIceMedia);
      setActiveIndex(2);
    }
  }, [props.selectedIceMedia]);

  /**
   * @function onPaginate
   * @description Pagination callback
   * @param {int} _index - Page number
   */
  const onPaginate = (event, value) => {
    setListingLoading(true);
    setListingPaging(true);
    setCurrentListingPage(value);
  };

  const RenderPagination = () => {
    // Pagination component
    if (totalListingItems <= pageSize) {
      return null;
    } else {
      let pagination = paginator(
        totalListingItems,
        currentListingPage,
        pageSize,
        3
      );

      return (
        <MuiPagination
          className="creator-pagination d-flex justify-content-center mt-4"
          count={pagination.totalPages}
          page={pagination.currentPage}
          siblingCount={2}
          boundaryCount={2}
          showFirstButton
          showLastButton
          color="primary"
          onChange={onPaginate}
        />
      );
    }
  };

  const onAssetPaginate = (event, value) => {
    setShouldOpenSelected(false);
    setMediaLoading(true);
    setMediaPaginating(true);
    setCurrentMediaPage(value);
  };

  const RenderAssetPagination = () => {
    // Pagination component
    if (totalMediaItems <= pageSize) {
      return null;
    } else {
      let pagination = paginator(
        totalMediaItems,
        currentMediaPage,
        pageSize,
        3
      );

      return (
        <MuiPagination
          className="creator-pagination d-flex justify-content-center mt-4"
          count={pagination.totalPages}
          page={pagination.currentPage}
          siblingCount={2}
          boundaryCount={2}
          showFirstButton
          showLastButton
          color="primary"
          onChange={onAssetPaginate}
        />
      );
    }
  };

  const onReset = () => {
    setSelectedRegion("All Regions");
    setSelectedCountry(defaultCountry);
    setSelectedChain("All Chains");
    setSelectedCity("");
    setCurrentListingPage(1);
  };

  const onAssetReset = () => {
    setMediaFileId("");
    setPublicMediaId("");
    setPrivateMediaId("");
    setSelectedMediaType(props.mediaType ? props.mediaType : "PH");
    setCurrentMediaPage(1);
  };

  useEffect(() => {
    if (selectedListing) {
      // api call
      setActiveIndex(1);
      setMediaError(false);
      setMediaPaginating(false);
      setMediaLoading(true);

      let path = `/api/iceportal/assets?listingID=${selectedListing}&mediaType=${selectedMediaType}&pageSize=${pageSize}&pageNo=${currentMediaPage}`;
      if (mediaFileId) {
        path = `${path}&fileId=${mediaFileId}`;
      }

      if (publicMediaId) {
        path = `${path}&publicID=${publicMediaId}`;
      }

      if (privateMediaId) {
        path = `${path}&privateID=${privateMediaId}`;
      }

      getOnlineNode(path)
        .then((response) => {
          setMedia(response.data.results);
          setTotalMediaItems(response.data.count);
          setMediaPaginating(false);
          setMediaLoading(false);
          setMediaError(false);
          setMediaOnProps();
        })
        .catch((_error) => {
          setMediaLoading(false);
          setMediaError(true);
        });
    } else {
      setActiveIndex(0);
      setMediaLoading(false);
      setMediaError(false);
      setMediaPaginating(false);
      setCurrentMediaPage(1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentListingPage, currentMediaPage, mediaFileId, publicMediaId, privateMediaId, selectedMediaType, selectedListing]);

  const setMediaOnProps = () => {
    if (props.selectedIceMedia && shouldOpenSelected) {
      setSelectedMedia(props.selectedIceMedia);
      setActiveIndex(2);
    }

    // setShouldOpenSelected(true);
  };

  const GalleryElement = ({ photo }) => {
    return (
      <IceElement
        serverUrl={serverUrl}
        key={photo.assetId}
        photo={photo}
        hidden={photo.hidden}
        selectedAssetId={props.selectedAssetId}
        selectedFileId={props.selectedFileId}
        selectedListingId={props.selectedListingId}
        selectedListingName={props.selectedListingName}
        selectedIceMedia={props.selectedIceMedia}
        onSelect={() => {
          setSelectedMedia(photo);
          setActiveIndex(2);
        }}
        refreshMedia={() => {
          props.refreshMedia();
        }}
      />
    );
  };

  const RenderGallery = () => {
    let normalPhotos = media.map((el) => {
      if (el.mediaType === 'PH') {
        return {
          ...el,
          src: el.links.cdnLinks[2],
          width: el.x ? el.x / 4 : 4,
          height: el.y ? el.y / 4 : 4,
        };
      }

      if (el.mediaType === 'MVI') {
        return {
          ...el,
          hidden: false,
          src: el.links.originalFileURL,
          width: el.x ? el.x / 4 : 4,
          height: el.y ? el.y / 4 : 4,
        };
      }

    });

    if (normalPhotos.length < 2) {
      normalPhotos[1] = {
        ...normalPhotos[0],
        hidden: true,
        assetId: normalPhotos[0].assetId + "001",
      };

      normalPhotos[2] = {
        ...normalPhotos[0],
        hidden: true,
        assetId: normalPhotos[0].assetId + "002",
      };
    }

    return (
      <Gallery
        photos={normalPhotos}
        renderImage={GalleryElement}
        targetRowHeight={180}
      />
    );
  };

  return (
    <>
      <Container fluid className="media-container">
        <div className="media-library">
          <Carousel
            activeIndex={activeIndex}
            controls={false}
            indicators={false}
            slide={false}
          >
            <Carousel.Item>
              <Row className="mb-3 mt-3 filter-input-container">
                <Col className="filter-input">
                  <FormControl
                    variant="outlined"
                    sx={{
                      m: 1,
                      width: "100%",
                      backgroundColor: "primary.light",
                    }}
                  >
                    <InputLabel
                      sx={{
                        fontSize: input_font_size,
                        color: "primary.main",
                      }}
                      id="region"
                    >
                      Region
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="region"
                      id="region-standard"
                      value={selectedRegion}
                      onChange={(event) => {
                        setCurrentListingPage(1);
                        setSelectedRegion(event.target.value);
                      }}
                      label="Region"
                      input={
                        <OutlinedInput
                          sx={{
                            fontSize: input_font_size,
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#c2ccd6",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderWidth: "2px",
                              borderColor: "primary.main",
                            },
                          }}
                          label="Region"
                        />
                      }
                    >
                      {regions.map((region, index) => {
                        if (region.regionCode !== "AM") {
                          return (
                            <MenuItem
                              key={`region-${index}`}
                              value={region.regionCode}
                            >
                              {region.regionName}
                            </MenuItem>
                          );
                        }
                      })}
                    </Select>
                  </FormControl>
                </Col>
                <Col className="filter-input">
                  <FormControl
                    sx={{
                      m: 1,
                      width: "100%",
                      backgroundColor: "primary.light",
                    }}
                  >
                    <Autocomplete
                      ListboxProps={{
                        sx: { fontSize: input_font_size },
                      }}
                      options={countries}
                      getOptionLabel={(option) => {
                        if (option.countryCode) {
                          return `${option.name} (${option.countryCode})`;
                        }

                        return `${option.name}`;
                      }}
                      value={selectedCountry}
                      disableClearable={true}
                      size="small"
                      freeSolo
                      onChange={(event, value) => {
                        if (value !== null) {
                          setSelectedCountry(value);
                        } else {
                          setSelectedCountry(defaultCountry);
                        }
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Country"
                          InputProps={{
                            ...params.InputProps,
                            type: "search",
                            style: { fontSize: input_font_size },
                            endAdornment: (
                              <InputAdornment position="start">
                                <SearchOutlined
                                  sx={{
                                    width: "20px",
                                    height: "18px",
                                  }}
                                />
                              </InputAdornment>
                            ),
                          }}
                          InputLabelProps={{
                            style: { fontSize: input_font_size },
                          }}
                          owner="admin"
                          sub_variant="presentation-filter"
                        />
                      )}
                    />
                  </FormControl>
                </Col>
                <Col className="filter-input">
                  <FormControl
                    variant="outlined"
                    sx={{
                      m: 1,
                      width: "100%",
                      backgroundColor: "primary.light",
                    }}
                  >
                    <InputLabel
                      sx={{
                        fontSize: input_font_size,
                        color: "primary.main",
                      }}
                      id="chain"
                    >
                      Chain
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="chain"
                      id="chain-standard"
                      value={selectedChain}
                      onChange={(event) => {
                        setCurrentListingPage(1);
                        setSelectedChain(event.target.value);
                      }}
                      label="Chain"
                      input={
                        <OutlinedInput
                          sx={{
                            fontSize: input_font_size,
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#c2ccd6",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderWidth: "2px",
                              borderColor: "primary.main",
                            },
                          }}
                          label="Chain"
                        />
                      }
                    >
                      {chains.map((chain, index) => (
                        <MenuItem key={`chain-${index}`} value={chain}>
                          {chain}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Col>
                {/* <Col className="filter-input">
                  <FormControl
                    variant="outlined"
                    size="small"
                    sx={{ m: 1, width: "100%" }}
                  >
                    <TextField
                      fullWidth
                      size="small"
                      value={fileId ? fileId : ""}
                      label="FileID"
                      onChange={(event) => {
                        setCurrentListingPage(1);
                        setFileId(event.target.value);
                      }}
                      InputProps={{
                        style: { fontSize: input_font_size },
                        endAdornment: fileId ? (
                          <IconButton
                            sx={{
                              padding: 0,
                            }}
                            onClick={() => {
                              setCurrentListingPage(1);
                              setFileId("");
                            }}
                          >
                            <Close
                              sx={{
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          </IconButton>
                        ) : (
                          <SearchOutlined
                            sx={{
                              width: "20px",
                              height: "20px",
                              marginBottom: "5px",
                              marginRight: "8px",
                            }}
                          />
                        ),
                      }}
                      InputLabelProps={{
                        style: { fontSize: input_font_size },
                      }}
                      sx={{
                        '& label[data-shrink="true"]': {
                          color: "primary.main",
                        },
                        '& label[data-shrink="false"]': {
                          color: "rgba(0, 0, 0, 0.87)",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                        "& .MuiOutlinedInput-root": {
                          backgroundColor: "primary.light",
                          paddingRight: "10px",
                          "& fieldset": {
                            borderColor: "#c2ccd6",
                          },
                          "&:hover fieldset": {
                            borderColor: "primary.main",
                            borderWidth: "2px",
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "primary.main",
                          },
                        },
                      }}
                    />
                  </FormControl>
                </Col> */}

                <Col className="filter-input reset d-flex align-items-center justify-content-center">
                  <Button
                    className="d-flex justify-content-center align-items-center reset-btn"
                    variant="outlined"
                    sx={{
                      fontSize: input_font_size,
                      textTransform: "none",
                    }}
                    onClick={() => onReset()}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
              {isListingLoading || isListingPaginating ? (
                <Row>
                  <Col>
                    <LoadingSpinner />
                  </Col>
                </Row>
              ) : (
                <Row className="media-container ice-portal gx-0">
                  {isListingError ? (
                    <NoData activeIndex={activeIndex} />
                  ) : (
                    <>
                      {listings.length > 0 ? (
                        <>
                          <Row className="gx-0 justify-content-between">
                            {listings &&
                              listings.map((listing, index) => {
                                return (
                                  <Col
                                    key={`listing-${index}`}
                                    xs={12}
                                    md={4}
                                    className="gallery-element-column"
                                  >
                                    <Button
                                      key={`el-${listing.listingID}`}
                                      className={"gallery-element"}
                                      onClick={() => {
                                        setSelectedListing(listing.listingID);
                                        setSelectedListingName(listing.name);
                                      }}
                                      sx={{
                                        textAlign: "left",
                                      }}
                                      startIcon={
                                        <FolderIcon className="mx-2" />
                                      }
                                    >
                                      <Typography
                                        variant="button"
                                        owner="admin"
                                        color="adminText.main"
                                        lh="base"
                                      >
                                        {decodeHtmlEntities(listing.name)}
                                      </Typography>
                                    </Button>
                                  </Col>
                                );
                              })}
                          </Row>
                          <RenderPagination />
                        </>
                      ) : (
                        <NoData activeIndex={activeIndex} />
                      )}
                    </>
                  )}
                </Row>
              )}
            </Carousel.Item>
            <Carousel.Item>
              <>
                <Row className="mt-3 mb-3 align-items-center">
                  <Col xs="12" md="3">
                    <div className={``}>
                      <BackButton
                        className="ice-variant"
                        label="Back to listings"
                        onCancel={() => {
                          props.setShouldOpenOverride(true);
                          props.clearSelectedListing();
                          setShouldOpenSelected(false);
                          setMediaFileId("");
                          setPublicMediaId("");
                          setPrivateMediaId("");
                          setSelectedListing(null);
                          setActiveIndex(0);
                        }}
                      />
                    </div>
                  </Col>
                  <Col
                    xs="12"
                    md="6"
                    className="justify-content-center align-items-center text-center mt-4 mb-3 my-md-0"
                  >
                    <h5 style={{ margin: 0 }}>
                      {decodeHtmlEntities(selectedListingName)} (Listing ID:{" "}
                      {selectedListing})
                    </h5>
                  </Col>
                </Row>
                <Row className="mt-3 mb-3 align-items-center">
                  <Col xs="12">
                    <Row className="justify-content-center filter-input-container">
                      <Col className="filter-input">
                        <FormControl
                          variant="outlined"
                          sx={{
                            m: 1,
                            width: "100%",
                            backgroundColor: "primary.light",
                          }}
                        >
                          <InputLabel
                            sx={{
                              fontSize: input_font_size,
                              color: "primary.main",
                            }}
                            id="media-type"
                          >
                            Media type
                          </InputLabel>
                          <Select
                            size="small"
                            labelId="media-type"
                            id="media-type-standard"
                            value={selectedMediaType}
                            onChange={(event) => {
                              setCurrentMediaPage(1);
                              setSelectedMediaType(event.target.value);
                            }}
                            label="Media type"
                            disabled={mediaTypeFilterDisabled}
                            input={
                              <OutlinedInput
                                sx={{
                                  fontSize: input_font_size,
                                  "& .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#c2ccd6",
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderWidth: "2px",
                                    borderColor: "primary.main",
                                  },
                                }}
                                label="Media type"
                              />
                            }
                          >
                            <MenuItem key={`media-type-PH`} value={"PH"}>
                              Image
                            </MenuItem>
                            <MenuItem key={`media-type-MVI`} value={"MVI"}>
                              Video
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Col>
                      <Col className="filter-input">
                        <FormControl
                          variant="outlined"
                          size="small"
                          sx={{ m: 1, width: "100%" }}
                        >
                          <TextField
                            fullWidth
                            size="small"
                            value={mediaFileId ? mediaFileId : ""}
                            label="File ID"
                            onChange={(event) => {
                              setCurrentMediaPage(1);
                              setMediaFileId(event.target.value);
                            }}
                            InputProps={{
                              style: { fontSize: input_font_size },
                              endAdornment: fileId ? (
                                <IconButton
                                  sx={{
                                    padding: 0,
                                  }}
                                  onClick={() => {
                                    setCurrentMediaPage(1);
                                    setMediaFileId("");
                                  }}
                                >
                                  <Close
                                    sx={{
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  />
                                </IconButton>
                              ) : (
                                <SearchOutlined
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    marginBottom: "5px",
                                    marginRight: "8px",
                                  }}
                                />
                              ),
                            }}
                            InputLabelProps={{
                              style: { fontSize: input_font_size },
                            }}
                            sx={{
                              '& label[data-shrink="true"]': {
                                color: "primary.main",
                              },
                              '& label[data-shrink="false"]': {
                                color: "rgba(0, 0, 0, 0.87)",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "primary.main",
                              },
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "primary.light",
                                paddingRight: "10px",
                                "& fieldset": {
                                  borderColor: "#c2ccd6",
                                },
                                "&:hover fieldset": {
                                  borderColor: "primary.main",
                                  borderWidth: "2px",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "primary.main",
                                },
                              },
                            }}
                          />
                        </FormControl>
                      </Col>
                      <Col className="filter-input">
                        <FormControl
                          variant="outlined"
                          size="small"
                          sx={{ m: 1, width: "100%" }}
                        >
                          <TextField
                            fullWidth
                            size="small"
                            value={publicMediaId ? publicMediaId : ""}
                            label="Public ID"
                            onChange={(event) => {
                              setCurrentMediaPage(1);
                              setPublicMediaId(event.target.value);
                            }}
                            InputProps={{
                              style: { fontSize: input_font_size },
                              endAdornment: publicMediaId ? (
                                <IconButton
                                  sx={{
                                    padding: 0,
                                  }}
                                  onClick={() => {
                                    setCurrentMediaPage(1);
                                    setPublicMediaId("");
                                  }}
                                >
                                  <Close
                                    sx={{
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  />
                                </IconButton>
                              ) : (
                                <SearchOutlined
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    marginBottom: "5px",
                                    marginRight: "8px",
                                  }}
                                />
                              ),
                            }}
                            InputLabelProps={{
                              style: { fontSize: input_font_size },
                            }}
                            sx={{
                              '& label[data-shrink="true"]': {
                                color: "primary.main",
                              },
                              '& label[data-shrink="false"]': {
                                color: "rgba(0, 0, 0, 0.87)",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "primary.main",
                              },
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "primary.light",
                                paddingRight: "10px",
                                "& fieldset": {
                                  borderColor: "#c2ccd6",
                                },
                                "&:hover fieldset": {
                                  borderColor: "primary.main",
                                  borderWidth: "2px",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "primary.main",
                                },
                              },
                            }}
                          />
                        </FormControl>
                      </Col>
                      <Col className="filter-input">
                        <FormControl
                          variant="outlined"
                          size="small"
                          sx={{ m: 1, width: "100%" }}
                        >
                          <TextField
                            fullWidth
                            size="small"
                            value={privateMediaId ? privateMediaId : ""}
                            label="Private ID"
                            onChange={(event) => {
                              setCurrentMediaPage(1);
                              setPrivateMediaId(event.target.value);
                            }}
                            InputProps={{
                              style: { fontSize: input_font_size },
                              endAdornment: setPublicMediaId ? (
                                <IconButton
                                  sx={{
                                    padding: 0,
                                  }}
                                  onClick={() => {
                                    setCurrentMediaPage(1);
                                    setPrivateMediaId("");
                                  }}
                                >
                                  <Close
                                    sx={{
                                      width: "20px",
                                      height: "20px",
                                    }}
                                  />
                                </IconButton>
                              ) : (
                                <SearchOutlined
                                  sx={{
                                    width: "20px",
                                    height: "20px",
                                    marginBottom: "5px",
                                    marginRight: "8px",
                                  }}
                                />
                              ),
                            }}
                            InputLabelProps={{
                              style: { fontSize: input_font_size },
                            }}
                            sx={{
                              '& label[data-shrink="true"]': {
                                color: "primary.main",
                              },
                              '& label[data-shrink="false"]': {
                                color: "rgba(0, 0, 0, 0.87)",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "primary.main",
                              },
                              "& .MuiOutlinedInput-root": {
                                backgroundColor: "primary.light",
                                paddingRight: "10px",
                                "& fieldset": {
                                  borderColor: "#c2ccd6",
                                },
                                "&:hover fieldset": {
                                  borderColor: "primary.main",
                                  borderWidth: "2px",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "primary.main",
                                },
                              },
                            }}
                          />
                        </FormControl>
                      </Col>
                      <Col className="filter-input reset d-flex align-items-center justify-content-center">
                        <Button
                          className="d-flex justify-content-center align-items-center reset-btn"
                          variant="outlined"
                          sx={{
                            fontSize: input_font_size,
                            textTransform: "none",
                          }}
                          onClick={() => onAssetReset()}
                        >
                          Reset
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {isMediaLoading || isMediaPaginating ? (
                  <Row>
                    <Col>
                      <LoadingSpinner />
                    </Col>
                  </Row>
                ) : (
                  <Row className="media-container">
                    {isMediaError ? (
                      <NoData activeIndex={0} />
                    ) : (
                      <>
                        {media.length > 0 ? (
                          <>
                            {RenderGallery()}
                            <RenderAssetPagination />
                          </>
                        ) : (
                          <NoData activeIndex={0} />
                        )}
                      </>
                    )}
                  </Row>
                )}
              </>
            </Carousel.Item>
            <Carousel.Item>
              {selectedMedia && selectedMedia.mediaType === 'PH' && (
                <SelectedIce
                  user={props.user}
                  listingId={selectedListing}
                  listingName={selectedListingName}
                  selectedMedia={selectedMedia}
                  cropApplied={cropApplied}
                  setCropApplied={setCropApplied}
                  isNotForInsert={props.isNotForInsert}
                  onError={props.onError}
                  activeIndex={activeIndex}
                  onUpload={() => {
                    //
                  }}
                  onMediaInsert={props.onMediaInsert}
                  onCancel={(iceValue, changeImage) => {
                    props.setShouldOpenOverride(true);
                    setShouldOpenSelected(false);
                    setActiveIndex(1);
                    setSelectedMedia(null);
                    props.setIce(iceValue);
                    if(changeImage){
                      props.clearSelectedListing();
                    }
                  }}
                  minWidth={props.minWidth}
                  minHeight={props.minHeight}
                  maxWidth={props.maxWidth}
                  maxHeight={props.maxHeight}
                  aspectRatio={props.aspectRatio}
                  maxSize={props.maxSize}
                  maxImageSize={props.maxImageSize}
                  maxAudioSize={props.maxAudioSize}
                  maxVideoSize={props.maxVideoSize}
                  currentBlock={props.currentBlock}
                  categories={props.categories}
                />
              )}

              {selectedMedia && selectedMedia.mediaType === 'MVI' && (
                <SelectedIceVideo
                  selectedMedia={selectedMedia}
                  onMediaInsert={props.onMediaInsert}
                  onCancel={(iceValue) => {
                    props.setShouldOpenOverride(true);
                    setShouldOpenSelected(false);
                    setActiveIndex(1);
                    setSelectedMedia(null);
                    props.setIce(iceValue);
                  }}
                  isNotForInsert={props.isNotForInsert}
                  user={props.user}
                  listingId={selectedListing}
                  listingName={selectedListingName}
                />
              )}
            </Carousel.Item>
          </Carousel>
        </div>
      </Container>
    </>
  );
}
