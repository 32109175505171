import React, { useState } from "react";

// Modules

// App
import { TwitterTimelineEmbed } from 'react-twitter-embed';
// import InstagramEmbed from 'react-instagram-embed';
import Error from "../../../partials/error";
import Oauth from '../../auth/oauth';
import HomeSkeletonPublicScreen from "../homeSkeletonPublicScreen";

// UI components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Twitter } from "react-feather";
import { Dialog, DialogContent, Button, DialogTitle, Typography } from '@mui/material';

export default function EquansPublicHome(props) {
  // constructor() {
  //   super();
  //   this.state = {
  //     isLoading: false,
  //     isRefreshing: false,
  //     modalVisible: true,
  //     isError: false,
  //     errorStatus: '',
  //     errorMessage: '',
  //   };
  // }

  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [modalVisible, setModalVisible] = useState(true);
  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const renderTwitterTimeline = () => {
    return (
      <Col xs={12} sm={8} className="mb-5 mb-md-0">
        <div className="twitter-container">
          <div className="twitter-container__header">
            <div className="twitter-brand">
              <div className="logo">
                <Twitter />
              </div>
              <span>Twitter</span>
            </div>
            <h6 className="twitter-handle">@CompanyappUK</h6>
          </div>
          <div className="twitter-container__timeline">
            <TwitterTimelineEmbed
              sourceType='profile'
              screenName='CompanyappUK'

            />
          </div>
        </div>
      </Col>
    );
  };

  if (isError) {
    return (
      <Error
        status={errorStatus}
        message={errorMessage}
      />
    );
  } else {
    if (isLoading) {
      return <HomeSkeletonPublicScreen />;
    } else {
      return (
        <main className='home--public home screen'>
          <Dialog
            open={modalVisible}
            maxWidth={'xs'}
            fullWidth={true}
            onClose={() => {
              setModalVisible(false);
            }}
            className='login_modal'
          >
            <DialogTitle
              sx={{
                backgroundColor: 'primary.light',
                color: 'solid.dark',
                paddingLeft: '34px',
                paddingRight: '34px',
              }}
            >
              <Row>
                <Col>
                  <Row>
                    <Col>
                      <Typography
                        sx={{
                          fontSize: '32px',
                          fontWeight: 500,
                          lineHeight: '51.2px',
                          spacing: '0.24px'
                        }}
                      >
                        Login
                      </Typography>
                    </Col>
                    <Col className="d-flex justify-content-end align-items-center" >
                      <Button
                        type="button"
                        variant='outlined'
                        sx={{
                          backgroundColor: 'primary.contrastText',
                          width: '130px',
                          height: '35px',
                          textTransform: 'none',
                          fontWeight: '400',
                        }}
                        className="close-modal-btn"
                        onClick={() => {
                          setModalVisible(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </DialogTitle>
            <DialogContent
              sx={{
                paddingLeft: '34px',
                paddingRight: '34px',
              }}
            >
              <Oauth type='modal' />
            </DialogContent>
          </Dialog>

          <Row className='mt-5 justify-content-center'>
            {/* {renderTwitterTimeline()} */}
          </Row>
        </main>
      );
    }
  }
}
