import React from "react";

import { favoritesEnabled } from "../../config";
import moment from "moment";

import GridItem from "../../partials/gridItem";
import FavouriteBtn from "../../partials/favouriteBtn";
import { useNavigate } from "react-router-dom";

export default function BlogItem(props) {

  const navigate = useNavigate();
  const timestamp = moment(props.item.date) / 1000

  return (
    <GridItem
      xs={12} sm={6} md={3}
      itemClass="blog"
      nid={props.item.id}
      title={props.item.title.rendered}
      field_body={
        props.item.excerpt.rendered
          ? props.item.excerpt.rendered
          : props.item.content.rendered
      }
      category_label={props.categoryName}
      created={timestamp}
      hasImg={true}
      imgSrc={`${props.item._embedded['wp:featuredmedia'][0]['source_url']}`}
      cardAction={true}
      cardActionCallBack={() => navigate(`/blogs/${props.item?.nid}`)}
      cardActions={true}
    >
      {favoritesEnabled && (
        <FavouriteBtn
          contentType={'blogs'}
          id={props.item.id}
        />
      )}
    </GridItem>
  )
}