import React, { useEffect, useState } from "react";

// Modules
import { ThemeProvider } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";

// App
import {
  pageTitle,
  permissionsEnabled,
  themesEnabled,
  favoritesEnabled,
  appConfigEnabled,
  notificationsEnabled,
} from "./config";
import Routes from "./core/routes";
import { Sidebar } from "./config";
import { getOnlineNode } from "../src/core/getNode";
import Header from "./partials/header";
import LoadingSpinner from "./partials/loadingSpinner";
import ScrollToTop from "./partials/scrollToTop";
import ClientTheme from "./core/clientTheme";
import { useWindowSize } from "./partials/windowSize";

// UI components
import Container from "react-bootstrap/Container";

// Style
import { companyappTheme } from "./core/theme";
import "./styles/main.scss";

export default function App() {
  const [isLoading, setLoading] = useState(true);
  const [isThemesLoading, setThemesLoading] = useState(true);
  const [isMobile, setMobile] = useState(
    window.innerWidth < 993 ? true : false
  );
  const [isFluid, setFluid] = useState(false);
  const [navbarState, setNavbarState] = useState("nav-collapsed");

  // hooks
  // const location = useLocation();
  const showNavigation = useSelector((state) => state).navigationReducer
    .showNavigation;
  const user = useSelector((state) => state).authReducer.user;
  const currentLocation = useSelector(
    (state) => state.locationReducer.currentLocation
  );
  const { width } = useWindowSize();

  const isPresentationView =
    currentLocation === "preview-presentations" ||
    currentLocation === "view-presentations"
      ? true
      : false;

  // hooks
  const dispatch = useDispatch();
  // const navigate = useNavigate();

  const handleResize = () => {
    const { innerWidth: width } = window;
    if (
      width < 993 ||
      (currentLocation === "presentations-creator" && width < 1439)
    ) {
      if (showNavigation) {
        dispatch({ type: "TOGGLE_NAVIGATION", payload: false });
      }

      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  const checkSession = (_user) => {
    getOnlineNode("user/" + _user.current_user.uid)
      .then(() => {
        dispatch({ type: "SAVE_SESSION", payload: _user });
        checkPermissions(_user);
      })
      .catch((_error) => {
        dispatch({ type: "REMOVE_SESSION", payload: {} });
        setLoading(false);
      });
  };

  const checkPermissions = (_user) => {
    if (_user.current_user) {
      if (permissionsEnabled) {
        getOnlineNode(`api/user_permissions/${_user.current_user.uid}`)
          .then((response) => {
            if (response.data) {
              dispatch({ type: "SAVE_PERMISSIONS", payload: response.data });
              setLoading(false);
            }
          })
          .catch((error) => {
            setLoading(false);
          });
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const sidebarVisibility = () => {
    dispatch({ type: "TOGGLE_NAVIGATION", payload: !showNavigation });
  };

  // set page title
  useEffect(() => {
    document.title = pageTitle;
  }, []);

  // resize effect
  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [currentLocation]);

  // store client theme to redux
  useEffect(() => {
    dispatch({ type: "SET_CLIENT_THEME", payload: { ...ClientTheme() } });
  }, []);

  // check user
  useEffect(() => {
    let session = localStorage.getItem("user");
    session = JSON.parse(session);
    session = {
      user: session,
    };

    if (session && session.user && session.user.current_user) {
      checkSession(session.user);
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get config
  useEffect(() => {
    if (appConfigEnabled) {
      getOnlineNode("api/config")
        .then((response) => {
          dispatch({ type: "UPDATE_CONTENT", payload: response.data });
        })
        .catch((error) => {
          console.log("err: ", error);
        });
    }
  }, []);

  // Lister to user redux
  useEffect(() => {
    if (user.current_user) {
      if (permissionsEnabled) {
        getOnlineNode(`api/user_permissions/${user.current_user.uid}`)
          .then((response) => {
            if (response.data) {
              dispatch({ type: "SAVE_PERMISSIONS", payload: response.data });
              setLoading(false);
            }
          })
          .catch((error) => {
            setLoading(false);
          });
      }

      if (favoritesEnabled) {
        getOnlineNode(`api/favorites/${user.current_user.uid}?_format=json`)
          .then((response) => {
            if (response.data) {
              dispatch({ type: "UPDATE_FAVORITES", payload: response.data });
            }
          })
          .catch((error) => {
            setLoading(false);
          });
      }
    }
  }, [dispatch, user.current_user]);

  // Check for notifications
  useEffect(() => {
    if (user.current_user) {
      if (notificationsEnabled) {
        // getOnlineNode(`api/notifications?debug&_format=json`)
        getOnlineNode(`api/notifications?_format=json`)
          .then((response) => {
            dispatch({ type: "SAVE_NOTIFICATIONS", payload: response.data });
          })
          .catch((error) => {
            console.log("notifications error", error);
          });
      }
    }
  }, [dispatch, user.current_user]);

  // Re-gen csrf_token
  useEffect(() => {
    if (user.current_user) {
      getOnlineNode(`session/token?_format=json`)
        .then((response) => {
          let newUser = user;
          newUser.csrf_token = response.data;

          dispatch({ type: "SAVE_SESSION", payload: newUser });
        })
        .catch((error) => {});
    }
  }, [dispatch, user]);

  useEffect(() => {
    let _navState = "nav-collapsed";
    if (showNavigation || showNavigation === "true") {
      _navState = "nav-expanded";
    }

    setNavbarState(_navState);
  }, [showNavigation]);

  useEffect(() => {
    let _fluid = false;

    if (
      currentLocation === "presentations-creator" ||
      currentLocation === "edit-presentations" ||
      currentLocation === "view-presentations" ||
      currentLocation === "preview-presentations"
    ) {
      _fluid = true;
    }

    handleResize();
    setFluid(_fluid);
  }, [currentLocation]);

  // get themes
  useEffect(() => {
    if (themesEnabled) {
      getOnlineNode("api/themes")
        .then((response) => {
          dispatch({ type: "SAVE_THEMES", payload: response.data });
          setThemesLoading(false);
        })
        .catch((error) => {
          setThemesLoading(false);
        });
    } else {
      setThemesLoading(false);
    }
  }, []);

  if (isLoading || isThemesLoading) {
    return <LoadingSpinner className="spinner-container--app" />;
  }

  let theme = companyappTheme();

  return (
    <ThemeProvider theme={theme}>
      <ScrollToTop />
      {isPresentationView ? (
        <>
          <div className={`page-container`} data-page={currentLocation}>
            <Container fluid={true} data-page={currentLocation}>
              <Routes />
            </Container>
          </div>
        </>
      ) : (
        <>
          <Header
            showNavigation={showNavigation}
            sidebarVisibility={sidebarVisibility}
            navbarState={navbarState}
          />
          <Sidebar
            showNavigation={showNavigation}
            isMobile={isMobile}
            sidebarVisibility={sidebarVisibility}
          />
          <div
            className={`page-container ${navbarState}`}
            data-page={currentLocation}
          >
            <Container
              fluid={isFluid}
              className={`${isFluid ? "" : "content-container"} ${
                width < 576 ? "px-4" : ""
              }`}
              data-page={currentLocation}
            >
              <Routes />
            </Container>
          </div>
        </>
      )}
    </ThemeProvider>
  );
}
