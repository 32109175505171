import React, { useEffect, useState, useRef, useCallback } from 'react';

// Modules
import moment from 'moment';
import { debounce } from 'lodash';

// App
import { serverUrl, filesystem_root, subdomain } from '../../config';
import { patchNode } from '../../core/postNode';
import { createPoster } from '../../core/createVideoPoster';

// UI components
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Button, Alert, Slider, Stack, Checkbox, Typography } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import BackButton from './backButton';

export default function SelectedVideo(props) {
  const [isError, setError] = useState(null);
  const [sliderValue, setSliderValue] = useState(props.selectedMedia.field_poster_timestamp ? props.selectedMedia.field_poster_timestamp : 0);
  const [duration, setDuration] = useState(0);
  const [isPatching, setPatching] = useState(false);
  const video = useRef(null);
  const [poster, setPoster] = useState(`${serverUrl}/api/video/thumbnail?mid=${props.selectedMedia.mid}`);

  // core
  const [coreLibrary, setCoreLibrary] = useState(props.selectedMedia.core_library === '1' ? true : false);

  const formatDuration = (value) => {
    const minute = Math.floor(value / 60);
    const secondLeft = value - minute * 60;
    return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  }

  const handleLoadedMetadata = () => {
    if (video) {
      setDuration(video.current.duration);
      // createPoster(video.current, props.selectedMedia.field_media_video, sliderValue);
    }
  }

  const handleTimestamp = (value) => {
    setSliderValue(value);
    debounceUpdate(value);
  }

  const handleCoreLibrary = (checked) => {
    setPatching(true);
    setCoreLibrary(checked ? true : false);
    syncData(sliderValue, checked);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceUpdate = useCallback(
    debounce((value) => {
      setPatching(true);
      syncData(value, coreLibrary);
    }, 1500, true),
    []
  );

  /** Updates video poster timestamp */
  const syncData = (value, core_library) => {
    const data = {
      mid: props.selectedMedia.mid,
      field_poster_timestamp: value,
      core_library: core_library,
    };

    patchNode(
      `media_library/${props.selectedMedia.mid}`, data, props.user.csrf_token)
      .then(_response => {
        setPatching(false);
        // props.onTimestampUpdate();
      })
      .catch(error => {
        setPatching(false);
      });
  };

  useEffect(() => {
    const image = new Image();

    image.onload = function() {
      setPoster(`${serverUrl}/api/video/thumbnail?mid=${props.selectedMedia.mid}&t=${Date.now()}`);
    }

    image.onerror = function() {
      createPoster(video.current, props.selectedMedia.field_media_video, sliderValue);
    }

    setTimeout(() => {
      image.src = `${serverUrl}/api/video/thumbnail?mid=${props.selectedMedia.mid}`;
    }, 5000);
  }, [props.selectedMedia.field_media_video, props.selectedMedia.mid, sliderValue]);

  return (
    <>
      {isError ? (
        <>
          <Row className='mt-3 mb-3 justify-content-center'>
            <Col>
              <div className={`crop-actions ${props.isNotForInsert ? 'singular' : ''}`}>
                <BackButton onCancel={props.onCancel} />
              </div>
            </Col>
          </Row>


          <Row>
            <Col>
              <Alert
                variant='filled'
                severity='error'
                onClose={() => {
                  setError(null);
                }}
              >
                <p>{isError}</p>
              </Alert>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row className='mt-3 mb-3 justify-content-center'>
            <Col>
              <div className={`crop-actions ${props.isNotForInsert ? 'singular' : ''}`}>
                <BackButton onCancel={props.onCancel} />
                {!props.isNotForInsert && (
                  <Stack direction='row' spacing={2} textAlign='center'>
                    <Button variant='contained' color='secondary' endIcon={<DoneIcon />} onClick={() => {
                      props.onMediaInsert({
                        ...props.selectedMedia,
                        field_poster_timestamp: sliderValue,
                      });
                    }} >Select</Button>

                  </Stack>
                )}
              </div>
            </Col>
          </Row>

          <Row className='mt-3 mb-3 justify-content-center'>
            <Col>
              <div className='text-center'>
                <video
                  ref={video}
                  poster={poster}
                  className='video-item'
                  controls={true}
                  crossOrigin='use-credentials'
                  src={props.selectedMedia.field_media_video}
                  onLoadedMetadata={handleLoadedMetadata}
                  onError={(e) => {
                    setError(`Failed to load video`);
                  }}>
                  Your browser does not support the video element.
                </video>
              </div>
            </Col>
            <Col sm={12} md={6} className={'mb-3 info-container'}>
              <div className="wrapper">

                <div className='form-group'>
                  <Row className='mb-2 align-items-center info-row'>
                    <Col xs={4} sm={3}>
                      <Typography variant="form-group-label" owner="admin">
                        File name
                      </Typography>
                    </Col>
                    <Col>
                      <Typography
                        variant="form-group-label"
                        owner="admin"
                        weight="regular"
                        color="adminText.main"
                      >
                        {props.selectedMedia.name}
                      </Typography>
                    </Col>
                  </Row>
                  <Row className='mb-2 align-items-center info-row'>
                    <Col xs={4} sm={3}>
                      <Typography variant="form-group-label" owner="admin">
                        MID
                      </Typography>
                    </Col>
                    <Col>
                      <Typography
                        variant="form-group-label"
                        owner="admin"
                        weight="regular"
                        color="adminText.main"
                      >
                        {props.selectedMedia.mid}
                      </Typography>
                    </Col>
                  </Row>
                  <Row className='mb-2 align-items-center info-row'>
                    <Col xs={4} sm={3}>
                      <Typography variant="form-group-label" owner="admin">
                        Created
                      </Typography>
                    </Col>
                    <Col>
                      <Typography
                        variant="form-group-label"
                        owner="admin"
                        weight="regular"
                        color="adminText.main"
                      >
                        {moment.unix(props.selectedMedia.created).format("h:mm:ssa - DD/MM/YYYY")}
                      </Typography>
                    </Col>
                  </Row>
                  <Row className='mb-2 align-items-center info-row'>
                    <Col xs={4} sm={3}>
                      <Typography variant="form-group-label" owner="admin">
                        Thumbnail timestamp
                      </Typography>
                    </Col>
                    <Col>
                      <Typography
                        variant="form-group-label"
                        owner="admin"
                        weight="regular"
                        color="adminText.main"
                      >
                        {formatDuration(sliderValue)}
                      </Typography>
                    </Col>
                  </Row>
                  <Row className='mb-2 align-items-center info-row'>
                    <Col>
                      <Slider
                        sx={{ width: '95%', left: '2%' }}
                        max={duration}
                        min={0}
                        value={sliderValue}
                        onChange={(event, value) => {
                          handleTimestamp(value);
                        }}
                        steps={1}
                        disabled={isPatching ? true : false}
                      />
                    </Col>
                  </Row>
                  {props.permissions.access_media_promote_to_library && (
                    <Row className='mb-2 align-items-center info-row'>
                      <Col xs={4} sm={3}>
                        <Typography variant="form-group-label" owner="admin">
                          Corporate library
                        </Typography>
                      </Col>
                      <Col>
                        <Checkbox
                          sx={{
                            marginLeft: '-13px'
                          }}
                          size='small'
                          id='core-lib-checkbox'
                          disabled={isPatching}
                          checked={coreLibrary}
                          onChange={(event) => {
                            handleCoreLibrary(event.target.checked ? true : false);
                          }}
                        />
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};