import React, { useState, useEffect, useRef, useCallback } from "react";

// Modules
import moment from "moment";
import { debounce } from "lodash";
import { Cropper } from "react-advanced-cropper";

// App
import { serverUrl } from "../../config";
import { patchNode } from "../../core/postNode";
import CategorySelector from "../categorySelector";
import BackButton from "./backButton";

// UI components
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  Checkbox,
  CircularProgress,
  Alert,
  TextField,
  FormControl,
  Box,
  Button,
  Stack,
  Tooltip,
  Typography,
  SvgIcon,
} from "@mui/material/";
import { Crop, Done, Close, Send } from "@mui/icons-material";
import "react-advanced-cropper/dist/style.css";
import "react-advanced-cropper/dist/themes/corners.css";

export default function SelectedImage(props) {
  const [isCropping, setIsCropping] = useState(false);
  const [isInteracted, setInteracted] = useState(false);
  const [isError, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isPatching, setPatching] = useState(false);

  // Alt
  const [alt, setAlt] = useState(
    props.selectedMedia.alt ? props.selectedMedia.alt : ""
  );
  // core
  const [coreLibrary, setCoreLibrary] = useState(
    props.selectedMedia.core_library === "1" ? true : false
  );
  // category
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedCategoryItem, setSelectedCategoryItem] = useState(
    props.selectedMedia
      ? {
        value: props.selectedMedia?.category?.tid
          ? props.selectedMedia?.category?.tid
          : 0,
      }
      : { value: 0 }
  );

  const selectedMedia = props.selectedMedia;
  const width = props.selectedMedia.properties[0];
  const height = props.selectedMedia.properties[1];
  const image = `${serverUrl}/api/image?mid=${selectedMedia.mid}&x=0&y=0&width=${width}&height=${height}`;

  const cropperRef = useRef(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [croppedCoordinates, setCropCoordinates] = useState({});

  const [defaultCrop, setDefaultCrop] = useState({
    left: 0,
    top: 0,
    width: props.minWidth,
    height: props.minHeight,
  });

  useEffect(() => {
    let defaultCropParams = {
      left: (props.selectedMedia.properties[0] - props.minWidth) / 2,
      top: (props.selectedMedia.properties[1] - props.minHeight) / 2,
      width: props.minWidth,
      height: props.minHeight
    };

    if (
      props.currentBlock &&
      props.currentBlock?.details?.name !== "gallery"
    ) {
      const { x, y, width, height } =
        props.currentBlock?.data;

      if (x && y && width && height) {
        defaultCropParams = {
          left: x,
          top: y,
          width: width,
          height: height,
        };
      }
    }

    setDefaultCrop(defaultCropParams);
  }, [props.currentBlock, props.minHeight, props.minWidth, props.selectedMedia.properties]);

  const handleAlt = (event) => {
    setAlt(event.target.value);
    debounceAltUpdate(event.target.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceAltUpdate = useCallback(
    debounce(
      (value) => {
        setPatching(true);
        syncData(value, selectedCategory, coreLibrary);
      },
      1500,
      true
    ),
    []
  );

  /** Updates image caption */
  const syncData = (alt, category, core_library) => {
    const data = {
      mid: props.selectedMedia.mid,
      alt: alt,
      category: category,
      core_library: core_library,
    };

    patchNode(
      `media_library/${props.selectedMedia.mid}`,
      data,
      props.user.csrf_token
    )
      .then((_response) => {
        setPatching(false);
        props.onAltUpdate();
      })
      .catch((error) => {
        setPatching(false);
      });
  };

  const onChange = () => {
    if (cropperRef.current) {
      const coordinates = cropperRef.current.getCoordinates();
      setCropCoordinates(coordinates);
    }
  };

  const onMoveEnd = () => {
    getCrop();
  };

  const onResizeEnd = () => {
    getCrop();
  };

  const getCrop = () => {
    if (cropperRef.current) {
      const canvas = cropperRef.current.getCanvas();
      if (canvas) {
        const croppedImageDataUrl = canvas.toDataURL();
        const coordinates = cropperRef.current.getCoordinates();
        setCropCoordinates(coordinates);
        setCroppedImage(croppedImageDataUrl);
      }
    }
  };

  const finishCrop = () => {
    const data = {
      ...selectedMedia,
      mid: String(selectedMedia.mid),
      x: String(croppedCoordinates.left),
      y: String(croppedCoordinates.top),
      width: String(croppedCoordinates.width),
      height: String(croppedCoordinates.height),
      maxWidth: String(props.maxWidth),
      maxHeight: String(props.maxHeight),
      field_media_image: `${serverUrl}/api/image?mid=${selectedMedia.mid}&x=${croppedCoordinates.left}&y=${croppedCoordinates.top}&width=${croppedCoordinates.width}&height=${croppedCoordinates.height}&maxWidth=${props.maxWidth}&maxHeight=${props.maxHeight}`,
    };

    props.onMediaInsert(data);
  };

  const handleCategorySelection = (tid) => {
    let catItem = props.categories.filter((item) => {
      return item.value === tid;
    });

    setSelectedCategoryItem({
      label: catItem[0].label,
      value: catItem[0].value,
    });

    setPatching(true);
    setSelectedCategory(parseInt(catItem[0].value, 10));
    syncData(alt, catItem[0].value, coreLibrary);
  };

  const handleCoreLibrary = (checked) => {
    setPatching(true);
    setCoreLibrary(checked ? true : false);
    syncData(alt, selectedCategory, checked);
  };

  let cropStyles = {
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 0,
    visibility: "hidden",
    zIndex: -9990,
    width: "100%",
  };

  let cropImageStyles = {};

  if (isCropping) {
    cropStyles = {};
    cropImageStyles = {
      position: "absolute",
      top: 0,
      left: 0,
      opacity: 0,
      visibility: "hidden",
      zIndex: -9990,
      width: "100%",
    };
  }

  return (
    <>
      <Row className="mt-3 mb-3 justify-content-center">
        <Col>
          <div
            className={`crop-actions ${props.isNotForInsert ? "singular" : ""}`}
          >
            <Row className="w-100">
              <Col xs={6}>
                <Row className="justify-content-between">
                  <Col xs={"auto"}>
                    <BackButton onCancel={props.onCancel} className="default" />
                  </Col>
                  <Col xs={"auto"} className="d-flex align-items-center">
                    <Row>
                      <Col
                        xs={"auto"}
                        className="d-flex align-items-center justify-content-between"
                      >
                        {!props.isNotForInsert ? (
                          <Button
                            owner="admin"
                            variant="outlined"
                            dialogbtn="true"
                            dialogvariant="cancel"
                            className="mx-0"
                            onClick={() => props.onCancel()}
                            endIcon={
                              <SvgIcon>
                                <svg
                                  width="1200pt"
                                  height="1200pt"
                                  version="1.1"
                                  viewBox="0 0 1200 1200"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g fill="currentColor">
                                    <path d="m1150.8 573.6h-124.8c-13.199-224.4-199.2-400.8-426-400.8-73.199 0-145.2 19.199-207.6 54l-22.801 13.199c-9.6016 6-13.199 18-7.1992 27.602l36 55.199c6 8.3984 16.801 12 26.398 6l19.199-10.801c48-26.398 102-40.801 156-40.801 169.2 0 307.2 129.6 320.4 295.2h-121.2c-15.602 0-25.199 16.801-16.801 30l175.2 270c7.1992 12 25.199 12 32.398 0l175.2-270c9.6055-12 1.2031-28.797-14.395-28.797z" />
                                    <path d="m801.6 877.2c-6-8.3984-16.801-12-26.398-6l-19.203 10.801c-49.199 26.398-100.8 40.801-156 40.801-169.2 0-307.2-130.8-321.6-295.2h121.2c15.602 0 25.199-16.801 16.801-30l-175.2-271.2c-7.1992-12-25.199-12-32.398 0l-175.2 270c-8.3984 13.199 1.1992 30 16.801 30h123.6c13.195 223.2 199.2 400.8 426 400.8 72 0 144-19.199 207.6-54l22.801-13.199c9.6016-6 13.199-18 7.1992-27.602z" />
                                  </g>
                                </svg>
                              </SvgIcon>
                            }
                          >
                            <Typography
                              owner="admin"
                              variant="button"
                              texTransform="none"
                            >
                              Change image
                            </Typography>
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Col>
                      <Col xs={"auto"}>
                        {isCropping ? (
                          <Button
                            owner="admin"
                            variant="contained"
                            dialogbtn="true"
                            dialogvariant="error"
                            className='mx-0'
                            endIcon={<Close />}
                            onClick={() => setIsCropping(false)}
                          >
                            <Typography
                              owner="admin"
                              variant="button"
                              texTransform="none"
                            >
                              Cancel crop
                            </Typography>
                          </Button>
                        ) : (
                          <>
                            {!props.isNotForInsert && (
                              <>
                                {props.selectedMedia.properties[0] >=
                                  props.minWidth &&
                                  props.selectedMedia.properties[1] >=
                                  props.minHeight && (
                                    <Button
                                      owner="admin"
                                      variant="contained"
                                      dialogbtn="true"
                                      dialogvariant="primary"
                                      className="mx-0"
                                      endIcon={<Crop />}
                                      onClick={() => setIsCropping(true)}
                                    >
                                      <Typography
                                        owner="admin"
                                        variant="button"
                                        texTransform="none"
                                      >
                                        Crop image
                                      </Typography>
                                    </Button>
                                  )}
                              </>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs={6} className="d-flex align-items-center">
                {isCropping ? (
                  <>
                    <Button
                      variant="contained"
                      owner="admin"
                      dialogbtn="true"
                      dialogvariant="save"
                      className="mx-0"
                      endIcon={<Send />}
                      onClick={() => finishCrop()}
                    >
                      <Typography
                        owner="admin"
                        variant="button"
                        texTransform="none"
                      >
                        Save crop
                      </Typography>
                    </Button>
                  </>
                ) : (
                  <>
                    {!props.isNotForInsert && (
                      <>
                        <Button
                          variant="contained"
                          owner="admin"
                          dialogbtn="true"
                          dialogvariant="save"
                          className="mx-0"
                          endIcon={<Done />}
                          onClick={() => {
                            if (
                              props.selectedMedia.properties[0] <=
                              props.minWidth ||
                              props.selectedMedia.properties[1] <=
                              props.minHeight
                            ) {
                              setError(
                                `This image is below the required dimensions.`
                              );
                            } else if (
                              props.selectedMedia.properties[0] >
                              props.maxWidth ||
                              props.selectedMedia.properties[1] >
                              props.maxHeight
                            ) {
                              setError(
                                `This image exceeds the maximum dimensions.`
                              );
                            } else {
                              props.onMediaInsert(props.selectedMedia);
                            }
                          }}
                        >
                          <Typography
                            owner="admin"
                            variant="button"
                            texTransform="none"
                          >
                            Select image
                          </Typography>
                        </Button>
                      </>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row className="image-selection mb-3">
        <Col sm={12} lg={6} className={"mb-3"}>
          {isLoading && <CircularProgress />}
          {!props.isNotForInsert && (
            <>
              {props.minWidth !== undefined &&
                props.minHeight !== undefined && (
                  <div>
                    <Alert severity="warning">
                      <Stack direction={"row"} spacing={1}>
                        {props.minWidth && props.minHeight && (
                          <label>{`SIZE REQUIRED (W x H): MIN ${props.minWidth} and ${props.minHeight} pixels / MAX = ${props.maxWidth} x ${props.maxHeight} pixels`}</label>
                        )}
                      </Stack>
                    </Alert>
                  </div>
                )}
            </>
          )}
          <div className="crop-container">
            <div className="cropper">
              <div style={{ position: "relative" }}>
                <div style={cropStyles}>
                  <Cropper
                    ref={cropperRef}
                    key={image}
                    src={image}
                    backgroundWrapperProps={{
                      scaleImage: false,
                    }}
                    imageRestriction={"fitArea"}
                    onChange={onChange}
                    onMoveEnd={onMoveEnd}
                    onResizeEnd={onResizeEnd}
                    className={"cropper"}
                    resizeImage={false}
                    moveImage={false}
                    checkOrientation={false}
                    minWidth={props?.minWidth}
                    minHeight={props?.minHeight}
                    onReady={(cropper) => {
                      setTimeout(() => {
                        getCrop();
                        setLoading(false);
                      }, 1000);
                    }}
                    stencilProps={{
                      handlers: true,
                      lines: true,
                      aspectRatio: props?.aspectRatio ? props?.aspectRatio : "",
                      movable: true,
                    }}
                    defaultSize={defaultCrop}
                    defaultPosition={defaultCrop}
                  />
                </div>

                <img
                  key={`image-${props?.selectedMedia?.field_media_image}`}
                  style={cropImageStyles}
                  src={props?.selectedMedia?.field_media_image}
                  alt={`media-${props?.selectedMedia?.field_media_image}`}
                />
              </div>
            </div>
          </div>
        </Col>
        {isCropping ? (
          <>
            {/* {!isInteracted && (
              <Col sm={12} lg={6} className={"mb-3"}>
                <div>
                  <Alert severity="warning">
                    <Stack direction={"row"} spacing={1}>
                      {props.minWidth && props.minHeight && (
                        <label>
                          You must interact with the crop handle before you can
                          save image.
                        </label>
                      )}
                    </Stack>
                  </Alert>
                </div>
              </Col>
            )} */}
            {croppedImage && (
              <Col sm={12} lg={6} className={"mb-3"}>
                <div>
                  <Alert severity="success">
                    <Stack direction={"row"} spacing={1}>
                      {props.minWidth && props.minHeight && (
                        <label>{`CROP POSITION: X = ${Math.ceil(
                          croppedCoordinates.left
                        )}, Y = ${Math.ceil(
                          croppedCoordinates.top
                        )}, CROP SIZE: W = ${Math.ceil(
                          croppedCoordinates.width
                        )}, H = ${Math.ceil(
                          croppedCoordinates.height
                        )} pixels`}</label>
                      )}
                    </Stack>
                  </Alert>
                </div>
                <Row className="align-items-end h-100">
                  <Col xs={12} className="align-self-start">
                    <div
                      className={`img-preview ${isCropping ? "cropping" : ""}`}
                    >
                      <img src={croppedImage} alt="Cropped-preview" />
                    </div>
                  </Col>
                </Row>
              </Col>
            )}
          </>
        ) : (
          <Col sm={12} lg={6} className={"mb-3 info-container"}>
            {isError && (
              <div>
                <Alert variant="filled" severity="error">
                  <Stack direction={"row"} spacing={1}>
                    {props.minWidth && props.minHeight && (
                      <label>{isError}</label>
                    )}
                  </Stack>
                </Alert>
              </div>
            )}

            {!isError && !props.isNotForInsert && !isCropping && (
              <div style={{ visibility: "hidden" }}>
                <Alert severity="warning">
                  <Stack direction={"row"} spacing={1}>
                    {props.minWidth && props.minHeight && (
                      <label>{`SIZE REQUIRED (W x H): MIN ${props.minWidth} and ${props.minHeight} pixels / MAX = ${props.maxWidth} x ${props.maxHeight} pixels`}</label>
                    )}
                  </Stack>
                </Alert>
              </div>
            )}
            <div className="wrapper">
              {isPatching && (
                <Box className="info-loader">
                  <CircularProgress size={20} />
                </Box>
              )}

              <div className="form-group">
                <Row className="mb-2 align-items-center info-row">
                  <Col xs={4} sm={3}>
                    <Typography variant="form-group-label" owner="admin">
                      File name
                    </Typography>
                  </Col>
                  <Col>
                    <Typography
                      variant="form-group-label"
                      owner="admin"
                      weight="regular"
                      color="adminText.main"
                      break='break-all'
                    >
                      {props.selectedMedia.name}
                    </Typography>
                  </Col>
                </Row>
                <Row className="mb-2 align-items-center info-row">
                  <Col xs={4} sm={3}>
                    <Typography variant="form-group-label" owner="admin">
                      MID
                    </Typography>
                  </Col>
                  <Col>
                    <Typography
                      variant="form-group-label"
                      owner="admin"
                      weight="regular"
                      color="adminText.main"
                      break='break-all'
                    >
                      {props.selectedMedia.mid}
                    </Typography>
                  </Col>
                </Row>
                <Row className="mb-2 align-items-center info-row">
                  <Col xs={4} sm={3}>
                    <Typography variant="form-group-label" owner="admin">
                      Created
                    </Typography>
                  </Col>
                  <Col>
                    <Typography
                      variant="form-group-label"
                      owner="admin"
                      weight="regular"
                      color="adminText.main"
                      break='break-all'
                    >
                      {moment
                        .unix(props.selectedMedia.created)
                        .format("h:mm:ssa - DD/MM/YYYY")}
                    </Typography>
                  </Col>
                </Row>
                <Row className="mb-2 align-items-center info-row">
                  <Col xs={4} sm={3}>
                    <Typography variant="form-group-label" owner="admin">
                      Dimensions
                    </Typography>
                  </Col>
                  <Col>
                    {props.selectedMedia.properties[0] ? (
                      <Typography
                        variant="form-group-label"
                        owner="admin"
                        weight="regular"
                        color="adminText.main"
                        break='break-all'
                      >
                        {`${props.selectedMedia.properties[0]} x ${props.selectedMedia.properties[1]} pixels`}
                      </Typography>
                    ) : (
                      <Typography
                        variant="form-group-label"
                        owner="admin"
                        weight="regular"
                        color="adminText.main"
                      ></Typography>
                    )}
                  </Col>
                </Row>
                <Row className="mb-2 align-items-center info-row">
                  <Col xs={4} sm={3}>
                    <Typography variant="form-group-label" owner="admin" break="break-all">
                      Caption
                    </Typography>
                  </Col>
                  <Col style={{ position: "relative" }}>
                    <FormControl variant="standard" sx={{ width: "100%" }}>
                      <TextField
                        className="info-caption-input"
                        fullWidth
                        size="small"
                        readOnly={isPatching ? true : false}
                        disabled={isPatching ? true : false}
                        variant="outlined"
                        defaultValue={alt ? alt : ""}
                        // label='Add caption...'
                        placeholder="Add caption..."
                        onChange={(event) => {
                          handleAlt(event);
                        }}
                      />
                    </FormControl>
                  </Col>
                </Row>
                <Row className="mb-2 align-items-center info-row">
                  <Col xs={4} sm={3}>
                    <Typography variant="form-group-label" owner="admin">
                      Category
                    </Typography>
                  </Col>
                  <Col>
                    <CategorySelector
                      className="info-category-select"
                      size="small"
                      name="mediaCategory"
                      variant="outlined"
                      categories={props.categories}
                      selectedCategory={selectedCategoryItem}
                      handleCategorySelection={(tid) => {
                        handleCategorySelection(tid);
                      }}
                    />
                  </Col>
                </Row>
                {props.permissions.access_media_promote_to_library && (
                  <Row className="mb-2 align-items-center info-row">
                    <Col xs={4} sm={3}>
                      <Typography variant="form-group-label" owner="admin">
                        Corporate library
                      </Typography>
                    </Col>
                    <Col>
                      <Checkbox
                        sx={{
                          marginLeft: "-13px",
                        }}
                        size="small"
                        id="core-lib-checkbox"
                        checked={coreLibrary}
                        onChange={(event) => {
                          handleCoreLibrary(
                            event.target.checked ? true : false
                          );
                        }}
                      />
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </Col>
        )}
      </Row>
    </>
  );
}
