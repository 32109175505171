import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// Components
import RHGHome from "../screens/home/rhg/home";
import RHGPublicHome from "../screens/home/rhg/publicHome";
import CompanyappHome from "../screens/home/companyapp/home";
import CompanyappPublicHome from "../screens/home/companyapp/publicHome";
import EquansHome from "../screens/home/equans/home";
import EquansPublicHome from "../screens/home/equans/publicHome";

import SSOAuth from "../screens/auth/ssoauth";
import Login from "../screens/auth/login";
import AuthCallback from "../screens/auth/authCallback";
import Oauth from "../screens/auth//oauth";
import OAuthCallback from "../screens/auth/oauthCallback";

import Profile from "../screens/account/profile";
import Notifications from "../screens/account/notification";
import AddPresentation from "../screens/presentations/addPresentation";
import EditPresentation from "../screens/presentations/editPresentation";
import PresentationsCreator from "../screens/presentations/presentationsCreator";
import Presentations from "../screens/presentations/presentationsIndex";
import ViewPresentation from "../screens/presentations/viewPresentation";
import Radisson4u from "../screens/r4u/r4u";
import AddIn from "../screens/account/addIn";
import LaptopApp from "../screens/account/laptopApp";
import Training from "../screens/account/training";

//
import News from "../screens/news/news";
import NewsSingle from "../screens/news/newsSingle";
import Documents from "../screens/documents/documents";
import DocumentSingle from "../screens/documents//documentSingle";
import Videos from "../screens/videos/videos";
import VideosSingle from "../screens/videos/videoSingle";
import Polls from "../screens/polls/polls";
import Links from "../screens/links/links";
import Forms from "../screens/forms/forms";
import FormSingle from "../screens/forms/formSingle";
import Podcasts from "../screens/podcasts/podcasts";
import PodcastSingle from "../screens/podcasts/podcastSingle";
import Quizzes from "../screens/quizzes/quizzes";
import QuizSingle from "../screens/quizzes/quizSingle";
import Events from "../screens/events/events";
import EventSingle from "../screens/events/eventSingle";
import Blogs from "../screens/blogs/blogs";
import BlogSingle from "../screens/blogs/blogSingle";
import ForumsBoard from "../screens/forums/forumsBoard";
import ForumTopics from "../screens/forums/forumTopics";
import ForumTopicSingle from "../screens/forums/forumTopicSingle";
import Galleries from "../screens/galleries/galleries";
import GallerySingle from "../screens/galleries/gallerySingle";
import Contacts from "../screens/contacts/contacts";
import ContactSingle from "../screens/contacts/contactSingle";
import CompanyDirectory from "../screens/company_directory/companyDirectory";
import CompanyDirectorySingle from "../screens/company_directory/companyDirectorySingle";
import CourseTraining from "../screens/training/training";
import CourseTrainingSingle from "../screens/training/trainingSingle";
import SearchScreen from "../screens/search/search";
import Contribute from "../partials/equans/contribute";

// App
import { client } from "../config";

export default function RoutesComponent(props) {
  const user = useSelector((state) => state).authReducer.user;

  const dispatch = useDispatch();
  const setLocation = (data) =>
    dispatch({ type: "SET_LOCATION", payload: data });
  const toggleHeader = (data) =>
    dispatch({ type: "TOGGLE_HEADER", payload: data });

  const location = useLocation();

  useEffect(() => {
    onRouteChanged();
  }, [location]);

  const onRouteChanged = () => {
    const _location = location.pathname.split("/");
    toggleHeader(true);

    if (_location[1].length > 0) {
      if (_location[1] === "presentations") {
        if (_location[2]) {
          const loc = `${_location[2]}-${_location[1]}`;
          setLocation(loc);

          if (
            loc === "view-presentations" ||
            loc === "preview-presentations" ||
            loc === "thumbnail-presentations"
          ) {
            toggleHeader(false);
          }
        } else {
          setLocation(_location[1]);
        }
      } else {
        setLocation(_location[1]);
      }
    } else {
      setLocation("home");
    }
  };



  if (user.current_user) {
    if (client === "radisson") {
      return (
        <Routes>
          <Route path="/" element={<RHGHome />} />
          <Route path="/account" element={<Profile />} />
          <Route path="/account/notifications" element={<Notifications />} />
          <Route path="/account/add-in" element={<AddIn />} />
          <Route path="/account/laptop-app" element={<LaptopApp />} />
          <Route path="/account/training" element={<Training />} />
          <Route path="/presentations" element={<Presentations />} />
          <Route
            path="/presentations-creator"
            element={<PresentationsCreator />}
          />
          <Route path="/presentations/add" element={<AddPresentation />} />
          <Route
            path="/presentations/edit/:id"
            element={<EditPresentation />}
          />
          <Route
            path="/presentations/view/:id"
            element={<ViewPresentation preview={false} />}
          />
          <Route
            path="/presentations/preview/:id"
            element={<ViewPresentation preview={true} />}
          />
          <Route path="/radissonforyou" element={<Radisson4u />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      );
    }

    if (client === "companyapp") {
      return (
        <Routes>
          <Route path="/" element={<CompanyappHome />} />
          <Route path="/account" element={<Profile />} />
          <Route path="/account/notifications" element={<Notifications />} />
          <Route path="/account/add-in" element={<AddIn />} />
          <Route path="/account/laptop-app" element={<LaptopApp />} />
          <Route path="/account/training" element={<Training />} />
          <Route path="/presentations" element={<Presentations />} />
          <Route
            path="/presentations-creator"
            element={<PresentationsCreator />}
          />
          <Route path="/presentations/add" element={<AddPresentation />} />
          <Route
            path="/presentations/edit/:id"
            element={<EditPresentation />}
          />
          <Route
            path="/presentations/view/:id"
            element={<ViewPresentation preview={false} />}
          />
          <Route
            path="/presentations/preview/:id"
            element={<ViewPresentation preview={true} />}
          />
          <Route path="/news" element={<News />} />
          <Route path="/news/:id" element={<NewsSingle />} />
          <Route path="/documents" element={<Documents />} />
          <Route path="/documents/:id" element={<DocumentSingle />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/videos/:id" element={<VideosSingle />} />
          <Route path="/polls" element={<Polls />} />
          <Route path="/links" element={<Links />} />
          <Route path="/forms" element={<Forms />} />
          <Route path="/forms/:id" element={<FormSingle />} />
          <Route path="/podcasts" element={<Podcasts />} />
          <Route path="/podcasts/:id" element={<PodcastSingle />} />
          <Route path="/quizzes" element={<Quizzes />} />
          <Route path="/quizzes/:id" element={<QuizSingle />} />
          <Route path="/events" element={<Events />} />
          <Route path="/events/:id" element={<EventSingle />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs/:id" element={<BlogSingle />} />
          <Route path="/forums" element={<ForumsBoard />} />
          <Route path="/forums/:tid" element={<ForumTopics />} />
          <Route path="/forums/:tid/:nid" element={<ForumTopicSingle />} />
          <Route path="/galleries" element={<Galleries />} />
          <Route path="/galleries/:id" element={<GallerySingle />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/contacts/:id" element={<ContactSingle />} />
          <Route path="/company_directory" element={<CompanyDirectory />} />
          <Route path="/training" element={<CourseTraining />} />
          <Route path="/training/:id" element={<CourseTrainingSingle />} />
          <Route
            path="/company_directory/:id"
            element={<CompanyDirectorySingle />}
          />
          <Route path="/search/:query" element={<SearchScreen />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      );
    }

    if (client === "equans") {
      return (
        <Routes>
          <Route path="/" element={<CompanyappHome />} />
          <Route path="/account" element={<Profile />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/:id" element={<NewsSingle />} />
          <Route path="/documents" element={<Documents />} />
          <Route path="/documents/:id" element={<DocumentSingle />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/videos/:id" element={<VideosSingle />} />
          <Route path="/polls" element={<Polls />} />
          <Route path="/links" element={<Links />} />
          <Route path="/forms" element={<Forms />} />
          <Route path="/forms/:id" element={<FormSingle />} />
          <Route path="/podcasts" element={<Podcasts />} />
          <Route path="/podcasts/:id" element={<PodcastSingle />} />
          <Route path="/quizzes" element={<Quizzes />} />
          <Route path="/quizzes/:id" element={<QuizSingle />} />
          <Route path="/training" element={<CourseTraining />} />
          <Route path="/training/:id" element={<CourseTrainingSingle />} />
          <Route path="/events" element={<Events />} />
          <Route path="/events/:id" element={<EventSingle />} />
          <Route path="/search/:query" element={<SearchScreen />} />
          <Route path="/contribute" element={<Contribute/>} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      );
    }
  } else {
    if (client === "radisson") {
      return (
        <Routes>
          <Route path="/" element={<RHGPublicHome />} />
          <Route
            path="/presentations/view/:id"
            element={<ViewPresentation preview={false} />}
          />
          <Route path="/basicAuth" element={<Login />} />
          <Route path="/login" element={<SSOAuth />} />
          <Route path="/login/callback" element={<AuthCallback />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      );
    }

    if (client === "companyapp") {
      return (
        <Routes>
          <Route path="/" element={<CompanyappPublicHome />} />
          <Route
            path="/presentations/view/:id"
            element={<ViewPresentation preview={false} />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      );
    }

    if (client === "equans") {
      return (
        <Routes>
          <Route path="/" element={<EquansPublicHome />} />
          <Route path="/basicAuth" element={<Login />} />
          <Route path="/login" element={<Oauth />} />
          <Route path="/login/callback" element={<OAuthCallback />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      );
    }
  }
}
