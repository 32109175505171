import { EmbedIcon, CloseIcon, ImageIcon } from '../../icons/icons';
import BtnTooltip from '../../tooltips/btnTooltip';
import { EdjsTooltips } from '../../tooltips/edjsTooltip';

/**
 * Build styles
 */
require('./index.css').toString();

export default class Embed {

  static get toolbox() {
    return {
      icon: EmbedIcon,
      title: 'Embed',
    };
  }

  static get isReadOnlySupported() {
    return true;
  }

  constructor({ data, config, api, readOnly }) {
    this.api = api;
    this.readOnly = readOnly;

    this._CSS = {
      baseClass: this.api.styles.block,
      loading: this.api.styles.loader,
      settingsButton: this.api.styles.settingsButton,
      settingsButtonActive: this.api.styles.settingsButtonActive,

      /**
       * Tool's classes
       */
      container: 'embed-container',
      wrapper: 'embed',
      newButton: 'embed-btn',
      replaceButton: 'embed-replace-btn',

      modalMain: 'embed-modal-main',
      modal: 'embed-modal',
      codeEditor: 'embed-editor',
      close: 'embed-close',
      blockBtn: 'block-btn',
      blockBtnSmall: 'small',
      blockBtnLarge: 'large',
      largeIcon: 'large-icon',
      xlIcon: 'xl-icon',
      xxlIcon: 'xxl-icon',
      clear: 'clear',
      modalImgContainer: 'embed-modal-fallback-img-container',
      modalImg: 'embed-modal-fallback-img',
      editorBlockModalBackdrop: 'editor-block-modal-backdrop',
      editorBlockModal: 'editor-block-modal',
      editorBlockModalTitle: 'editor-block-modal-title',
      editorBlockModalLabel: 'editor-block-modal-label',
      editorBlockModalText: 'editor-block-modal-text',
      editorBlockModalInput: 'editor-block-modal-input',
      editorBlockModalImgContainer: 'editor-block-modal-img-container',
      adminFont: 'admin-font'
    };

    /**
      * Tool's settings passed from Editor
      *
      * @type {TitleConfig}
      * @private
      */
    this._settings = config;

    this._data = {
      code: data.code || '',
      url: data.url || '',
      mid: data.mid || '',
      width: data.width || '',
      height: data.height || '',
      x: data.x || '',
      y: data.y || '',
      maxWidth: data.maxWidth || '',
      maxHeight: data.maxHeight || '',
    };

    this.container = document.createElement('div');
    this._element = this.getElement();
    this._modal = this.modalElement();
    this._modalFallbackElement = this.modalFallbackElement();
  }

  getElement() {
    const tag = document.createElement('div');
    tag.classList.add(this._CSS.wrapper);
    tag.innerHTML = this._data.code;

    return tag;
  }

  modalFallbackElement() {
    const imgContainer = document.createElement('div');
    const btn = document.createElement('button');
    BtnTooltip(btn, EdjsTooltips.embed.fallback)

    imgContainer.classList.add(this._CSS.modalImgContainer, 'ratio', 'ratio-16x9', this._CSS.editorBlockModalImgContainer);
    btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.xxlIcon);
    btn.innerHTML = `${ImageIcon}`;
    btn.onclick = () => {
      const blockIndex = this.api.blocks.getCurrentBlockIndex();
      const block = this.api.blocks.getBlockByIndex(blockIndex);
      this._settings.showMediaLibrary({
        details: block,
        data: this._data,
        bundle: 'image',
      });
    };

    if (this._data.url) {
      const fallbackImg = document.createElement('img');
      fallbackImg.setAttribute('src', this._data.url);
      fallbackImg.setAttribute('data-mid', this._data.mid);
      fallbackImg.setAttribute('data-x', this._data.x);
      fallbackImg.setAttribute('data-y', this._data.y);
      fallbackImg.setAttribute('data-width', this._data.width);
      fallbackImg.setAttribute('data-height', this._data.height);
      fallbackImg.setAttribute('data-maxwidth', this._data.maxWidth);
      fallbackImg.setAttribute('data-maxheight', this._data.maxHeight);

      fallbackImg.classList.add(this._CSS.modalImg);
      imgContainer.appendChild(fallbackImg);
    }

    imgContainer.appendChild(btn);

    return imgContainer;
  }

  modalElement() {
    const modal = document.createElement('div');
    const modalMain = document.createElement('div');
    const modalBackdrop = document.createElement('div');
    const tag = document.createElement('textarea');
    const close = document.createElement('button');
    const container = this.container
    const modalTitle = document.createElement('label');
    const fallbackTitle = document.createElement('label');

    modalMain.classList.add(this._CSS.modalMain);
    modalBackdrop.classList.add(this._CSS.editorBlockModalBackdrop);
    modal.classList.add(this._CSS.modal, this._CSS.editorBlockModal);
    modalTitle.classList.add('modal-title', this._CSS.adminFont, this._CSS.editorBlockModalLabel);
    modalTitle.innerHTML = "Paste embed code below"
    fallbackTitle.classList.add('modal-title', this._CSS.adminFont, this._CSS.editorBlockModalLabel);
    fallbackTitle.innerHTML = "Offline fallback image"
    close.classList.add(this._CSS.close);
    close.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.clear);
    tag.classList.add(this._CSS.codeEditor, this._CSS.editorBlockModalInput);

    close.innerHTML = CloseIcon;

    close.addEventListener('click', function (event) {
      event.preventDefault();
      modalMain.classList.remove('show');
      container.classList.remove('show');
    });

    tag.innerHTML = this._data.code || '';
    tag.setAttribute('placeholder', 'Paste your code here');

    const that = this;
    tag.addEventListener('input', function (event) {
      that._element.innerHTML = event.target.value;
    });


    modal.appendChild(close);
    modal.appendChild(modalTitle);
    modal.appendChild(tag);
    modal.appendChild(fallbackTitle)
    modal.appendChild(this.modalFallbackElement());

    modalMain.appendChild(modalBackdrop);
    modalMain.appendChild(modal);

    return modalMain;
  }

  showEditor() {
    this._modal.classList.add('show');
    this.container.classList.add('show');
  }

  render() {
    let tag = this._element;
    let btn = document.createElement('button');
    BtnTooltip(btn, EdjsTooltips.embed.modal);

    this.container.classList.add(this._CSS.container);

    if (this._data.code) {
      btn.classList.add(this._CSS.replaceButton);
      btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.largeIcon);
      btn.innerHTML = `${Embed.toolbox.icon}`;
    } else {
      btn.classList.add(this._CSS.newButton);
      btn.classList.add(this._CSS.blockBtn, this._CSS.blockBtnSmall, this._CSS.largeIcon);
      btn.innerHTML = `${Embed.toolbox.icon}`;
    }

    btn.onclick = () => {
      this.showEditor();
    };

    if (typeof tag !== 'undefined') {
      this.container.appendChild(tag);
    }

    this.container.appendChild(this._modal);

    this.container.appendChild(btn);
    return this.container;
  }

  save() {
    if (typeof this._element !== 'undefined') {
      this._data = {
        code: this._element.innerHTML || '',
        url: this._data.url || '',
        mid: this._data.mid || '',
        x: this._data.x || '',
        y: this._data.y || '',
        width: this._data.width || '',
        height: this._data.height || '',
        maxWidth: this._data.maxWidth || '',
        maxHeight: this._data.maxHeight || '',
      }
    } else {
      this._data = {
        code: '',
        url: this._data.url || '',
        mid: this._data.mid || '',
        x: this._data.x || '',
        y: this._data.y || '',
        width: this._data.width || '',
        height: this._data.height || '',
        maxWidth: this._data.maxWidth || '',
        maxHeight: this._data.maxHeight || '',
      }
    }

    return this._data;
  }
}