import React, { useState } from 'react';

// Modules

// App
import MediaLibrary from '../mediaLibrary';

// UI components
import { Container, Row, Col } from 'react-bootstrap';
import { Button, FormControlLabel, Checkbox, Typography } from '@mui/material';

export default function AddAudio(props) {
  const [showMediaLibrary, setShowMediaLibrary] = useState(false);
  // const [audio, setAudio] = useState(props.audioData);


  return (
    <>

      <MediaLibrary
        user={props.user}
        mediaType='audio'
        setShowMediaLibrary={(condition => {
          setShowMediaLibrary(condition);
        })}
        showMediaLibrary={showMediaLibrary}
        onMediaInsert={(media) => {
          props.onAudioChange({
            ...media,
            autoplay: false,
            loop: false
          })
        }}
        selectedMid={props.audioData?.mid}
      />

      {props.audioData ?
        <Container className={`adv-slide-settings-sub-container ${props?.audioData.src ? 'audio' : ''}`}>
          <Row className='mb-4'>
            <Col className='d-flex align-items-center'>
              <audio controls src={props?.audioData.src} />
            </Col>
            <Col>
              <FormControlLabel
                label='Autoplay'
                control={
                  <Checkbox
                    size='small'
                    checked={props?.audioData.autoPlay}
                    onChange={(event) => {
                      props.onAudioChange({
                        ...props?.audioData,
                        autoPlay: event.target.checked,
                      })
                    }}
                  />
                }
              />
              <FormControlLabel
                label='Loop audio'
                control={
                  <Checkbox
                    size='small'
                    checked={props?.audioData?.loop ? props?.audioData?.loop : false}
                    onChange={(event) => {
                      props.onAudioChange({
                        ...props?.audioData,
                        loop: event.target.checked,
                      })
                    }}
                  />
                }
              />
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col xs={4} className='pe-0'>
              <Typography
                variant="form-group-label"
                owner="admin"
              >
                File Name:
              </Typography>
            </Col>
            <Col>
              <Typography
                variant="form-group-label"
                owner="admin"
                color="adminText.dark"
                weight="light"
              >
                {props?.audioData?.name}
              </Typography>
            </Col>
          </Row>
          <Row className='mb-2'>
            <Col xs={4} className='pe-0'>
              <Typography
                variant="form-group-label"
                owner="admin"
              >
                MID:
              </Typography>
            </Col>
            <Col>
              <Typography
                variant="form-group-label"
                owner="admin"
                color="adminText.dark"
                weight="light"
              >
                {props?.audioData?.mid}
              </Typography>
            </Col>
          </Row>
          <Row className='mb-4'>
            <Col xs={4} className='pe-0'>
              <Typography
                variant="form-group-label"
                owner="admin"
              >
                File size:
              </Typography>
            </Col>
            <Col>
              <Typography
                variant="form-group-label"
                owner="admin"
                color="adminText.dark"
                weight="light"
              >
                45mb
              </Typography>
            </Col>
          </Row>
          <Row className='btn-grp'>
            <Col xs={6}>
              <Button
                className='change-btn'
                variant='contained'
                color='primary'
                onClick={() => {
                  setShowMediaLibrary(true)
                }}
              >
                Change audio
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                className='remove-btn'
                variant='contained'
                color='error'
                onClick={() => {
                  props.onAudioChange(null)
                }}
              >
                Remove audio
              </Button>
            </Col>
          </Row>
        </Container> :
        <Container className='adv-slide-settings-sub-container'>
          <Row className='hint'>
            <Typography
              variant="body2"
              owner="admin"
            >
              To attach a sound to the {props.slideSetting ? 'slide' : 'presentation'}, please select or upload an audio file via the media library
            </Typography>
          </Row>
          <Row className='d-flex justify-content-center'>
            <Col xs={'auto'}>
              <Button
                className='media-lib-btn'
                variant='contained'
                color='primary'
                onClick={() => {
                  setShowMediaLibrary(true)
                  // setSlideAudio(true)
                }}
              >
                <Typography
                  variant='button'
                  owner='admin'
                  className='lh'
                >
                  Media library
                </Typography>
              </Button>
            </Col>
          </Row>
        </Container>
      }
    </>
  )
}