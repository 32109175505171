import React from 'react';

// Modules
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// App
// import BlogItem from './blogItem';
import { getExternal } from '../../core/getNode';
// import Pagination from '../../core/pagination';
import Pagination from '../../partials/pagination';
import PageHeader from '../../partials/pageHeader';
import { alertMessages } from '../../partials/alertMessages';
import Error from '../../partials/error';
import NoData from '../../partials/noData';
import { decodeHtmlEntities } from '../../utils/helpers';
import BlogItem from './blogItem'

// Data
// import db from './../../core/db';
// import { getContentCount } from '../../core/getRecordCount';
// import { updateContentCounterPreviousCount } from '../../core/updateData';
// import { getFavorites, getFavouritesLength } from './../../core/getFavorites';

// UI components
import Row from 'react-bootstrap/Row';
import { FormControl, InputLabel, Select, MenuItem, Input } from '@mui/material';
import CompanyappLoader from '../../partials/companyappLoader';


export default function Blogs(props) {
  const [isLoading, setIsLoading] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorStatus, setErrorStatus] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [index, setIndex] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [categories, setCategories] = useState([]);
  const [token, setToken] = useState('');
  const [data, setData] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [selectedCategoryItem, setSelectedCategoryItem] = useState(0)
  const [categoryOpen, setCategoryOpen] = useState(false);

  const user = useSelector((state) => state).authReducer.user;

  useEffect(() => {
    if (categories.length <= 1) {
      getCategories();
    }
    getContent(1);
  }, [selectedCategoryItem])

  /**
   * @function setError
   * @description Updates the state of the component upon an error
   * @param {boolean} _isError
   * @param {int} _errorStatus
   * @param {string} _errorMessage
   */
  const _setError = (_isError, _errorStatus, _errorMessage) => {
    setIsLoading(false);
    setIsRefreshing(false);

    setIsError(_isError);
    setErrorStatus(_errorStatus);
    setErrorMessage(_errorMessage);
  };

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  const getContent = (_currentPage) => {
    let path = `wp-json/wp/v2/posts?per_page=${pageSize}&page=${_currentPage}&_embed`

    const tid = selectedCategory;

    if (tid !== 0) {
      path = `wp-json/wp/v2/posts?categories=${tid}&per_page=${pageSize}&page=${_currentPage}&_embed`
    }

    getExternal(path, )
      .then((response) => {
        setData(response.data);
        setTotalItems(response.headers['x-wp-total']);
        setIsLoading(false);
      })
      .catch((_error) => {
        if (_error.response) {
          _setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          _setError(true, 0, alertMessages.requestError.message);
        } else {
          _setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function getCategories
   * @description Retrives the category data from an API / Fallback to local realm object if there is no connection
   */
  const getCategories = () => {
    getExternal('wp-json/wp/v2/categories')
      .then((response) => {
        renderCategories(response.data);
      })
      .catch((_error) => {
        if (_error.response) {
          _setError(
            true,
            _error.response.status,
            _error.response.statusText,
          );
        } else if (_error.request) {
          _setError(true, 0, alertMessages.requestError.message);
        } else {
          _setError(true, 0, alertMessages.unkownError.message);
        }
      });
  };

  /**
   * @function onPaginate
   * @description Pagination callback
   * @param {int} _index - Page number
   */
  const onPaginate = (_index) => {
    setIsLoading(true);

    setCurrentPage(_index);
    getContent(_index);
  };

  /**
   * @function renderCategories
   * @description Updates the state with the retrived categories
   * @param {object} _categories
   */
  const renderCategories = (_data) => {
    let categories = [
      {
        value: 0,
        label: 'All Blogs',
      },
    ];

    _data.forEach(item => {
      categories.push({
        value: item.id,
        label: item.name,
      });
    });

    setCategories(categories);
  };

  /**
   * @function handleCategorySelection
   * @description Modal Picker callback / Updates the header title and sets the state to the selected category
   * @param {string} _id - Category ID
   */
  const handleCategorySelection = (value) => {
    setIsLoading(true);

    setIsError(false);
    setCurrentPage(1);
    setSelectedCategory(parseInt(value, 10));
    setSelectedCategoryItem(value);
    setCategoryOpen(false);
  };

  // Pagination component
  const renderPagination = () => {
    if (totalItems <= pageSize) {
      return null;
    } else {
      return (
        <Pagination
          owner="client"
          containerClasses={`creator-pagination d-flex justify-content-center align-items-center mt-4`}
          previousDisabled={currentPage === 1 ? true : false}
          nextDisabled={data.length < pageSize ? true : false}
          currentPage={currentPage}
          onPrevious={() => {
            onPaginate(currentPage - 1)
          }}
          onNext={() => {
            onPaginate(currentPage + 1)
          }}
        />
      );
    }
  };
  
  const getCategoryName = (id) => {
    let categoryName = categories.find((e) => e.value === id)
    return categoryName ? categoryName.label : ''
  }

  // Data component
  const dataComponent = () => {
    if (typeof data !== 'undefined' && data.length > 0) {
      return (
        <Row>
          {data.map((item, index) => {
            let catName = getCategoryName(item.categories[0])
            return (
              <BlogItem
                key={`blog-${index}`}
                item={item}
                categoryName={catName}
              />
            );
          })}
        </Row>
      );
    } else {
      return (
        <NoData activeIndex={activeIndex} />
      );
    }
  };

  /**
   * @function render
   * @description Default render method
   */

  return (
    <>
      {
        isError ?
          <Error
            status={errorStatus}
            message={errorMessage}
          />
          :
          <main className="blogs screen">
            <PageHeader
              pageName="Company Blogs"
              filters={true}
              owner='client'
              pageNameColor='clientText.dark'
              categorySelector={
                <FormControl variant="standard" fullWidth size='small' >
                  <InputLabel sx={{ fontSize: 14 }}>Blogs</InputLabel>
                  <Select
                    labelId='blogs-category-select-label'
                    id='blogs-category-select'
                    value={selectedCategoryItem}
                    label='Blogs'
                    open={categoryOpen}
                    onClose={() => setCategoryOpen(false)}
                    onOpen={() => setCategoryOpen(true)}
                    input={
                      <Input
                        sx={{
                          fontSize: 14,
                          '&.MuiInput-underline:before': {
                            borderBottomColor: '#d2d0cf',
                          },
                        }}
                        label='Blogs'
                      />
                    }
                  >
                    {categories.map((category, index) => {
                      return (
                        <MenuItem
                          key={`blogs-category-${category.value}`}
                          value={category.value}
                          onClick={() => {
                            handleCategorySelection(category.value)
                          }}
                        >
                          {decodeHtmlEntities(category.label)}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              }
              getContent={() => {
                setActiveIndex(0);
                setIsLoading(true);
                getContent(1);
              }}
              // getFavourites={() => {
              //   setActiveIndex(1);
              //   setIsLoading(true);
              //   getFavourites()
              // }}
              activeTabIndex={activeIndex}
            />
            {isLoading ?
              <CompanyappLoader />
              :
              <>
                {dataComponent()}
                {renderPagination()}
              </>
            }
          </main>
      }
    </>
  )
}
