// Dependencies
import React from "react";

// Modules
import { useState, useRef, useEffect } from "react";

// App
import { serverUrl } from "../../config";

// UI components
import { CircularProgress, Hidden } from "@mui/material";

export default function Contribute(props) {
  const [iframeLoading, setIframeLoading] = useState(true);
  const iframeRef = useRef(null);

  return (
    <main className="contribute single pt-5">
      <article className="article pt-5">
        <div className="article__body">
          {iframeLoading && (
            <div className="w-100 h-100 text-center">
              <CircularProgress />
            </div>
          )}
          <div class="ratio ratio-4x3"  style={{ overflowY: 'hidden', height: '100vh' }}>
            <iframe
              className={`${iframeLoading && "invisible"}`}
              src={`${serverUrl}/node/391`}
              ref={iframeRef}
              style={{ width: '100%', height: '1000px', border: 'none' }} 
              title="Iframe Content"
              onLoad={(iframe) => {
                if (iframe.target.contentWindow) {
                  // iframe.target.style.height =
                  //   iframe.target.contentWindow.document.body.scrollHeight +
                  //   "px";
                }

                setIframeLoading(false);
              }}
            />
          </div>
        </div>
      </article>
    </main>
  )
}