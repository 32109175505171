// Dependencies
import React from "react";

// Modules
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

// App
import { serverUrl, apiEndpoints } from "../../config";
import { decodeHtmlEntities } from "../../utils/helpers";
import { getOnlineNode } from "../../core/getNode";
import { postNode, patchNode } from "../../core/postNode";
import PostBody from "../../partials/postBody";
import { alertMessages } from "../../partials/alertMessages";
import Error from "../../partials/error";
import SkeletonSingleScreen from "../../partials/skeleton-screens/skeletonSingleScreen";
import PostImage from "../../partials/postImage";
import PostCheckBox from "../../partials/postCheckBox";
import AlertModal from "../../partials/alertModal";
import IframeDialog from "../../partials/iframeDialog";

// UI components
import { Row, Col } from "react-bootstrap";
import {
  Button,
  Box,
  Typography,
  CircularProgress,
  Alert
} from "@mui/material";
import SinglesHeader from "../../partials/singlesHeader";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

export default function CourseTrainingSingle(props) {
  const [isLoading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [checkboxLoading, setCheckboxLoading] = useState({});

  const [errorStatus, setErrorStatus] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [submitError, setSubmitError] = useState(false);

  const [post, setPost] = useState(null);

  const [checkBoxes, setCheckboxes] = useState({});
  const [confirmed, setConfirmed] = useState(false);
  const [itemLength, setItemLength] = useState(0);

  const [resourceType, setResourceType] = useState("");
  const [resourceUnavailable, setResourceUnavailable] = useState(false);

  const [showView, setShowView] = useState(false);
  const [viewMessageTitle, setViewMessageTitle] = useState("");
  const [viewMessage, setViewMessage] = useState("");
  const [courseIncomplete, setCourseIncomplete] = useState(false);
  const [formDialog, setFormDialog] = useState(false);
  const [formId, setFormId] = useState("");

  const user = useSelector((state) => state).authReducer.user;
  const params = useParams();

  useEffect(() => {
    const nid = params.id;

    if (nid) {
      getContent(nid);
    } else {
      setIsError(true);
      setErrorStatus(404);
      setErrorMessage("Not found");
    }
  }, [params.id]);


  useEffect(() => {
    if (post) {
      let total = 0;
      let checked = {}

      post.field_sections.forEach((section) => {
        section.referenced_entities.forEach((entity) => {
          if (entity.checked) {
            checked[entity.id] = true;
          }
        })
        total = total + section.referenced_entities.length;
      });
      setCheckboxes({ ...checked });
      setItemLength(total);
    }
  }, [post]);

  useEffect(() => {
    const length = Object.keys(checkBoxes).length;
    if ((length !== itemLength) && confirmed) {
      setConfirmed(false);
    }
  }, [checkBoxes, itemLength]);

  /**
   * @function getContent
   * @description Retrieves the data from an API / Fallback to local realm object if there is no connection
   */
  const getContent = (nid, entityInfo) => {
    let path = `${apiEndpoints.training}?nid=${nid}&status=1&promote=1`;
    getOnlineNode(path)
      .then((response) => {
        if (response.data.rows.length > 0) {
          setPost(response.data.rows[0]);
          setIsError(false);
          setErrorStatus(null);
          setErrorMessage(null);
          setLoading(false);
          if (entityInfo) {
            handleFormDialog(response.data.rows[0], entityInfo);
          }
          else {
            setCheckboxLoading({});
          }
        } else {
          setIsError(true);
          setErrorStatus(404);
          setErrorMessage("Not found");
        }
      })
      .catch((_error) => {
        setIsError(true);

        if (_error.response) {
          setErrorStatus(_error.response.status);
          setErrorMessage(_error.response.statusText);
        } else if (_error.request) {
          setErrorStatus(0);
          setErrorMessage(alertMessages.requestError.message);
        } else {
          setErrorStatus(0);
          setErrorMessage(alertMessages.unknownError.message);
        }

        setLoading(false);
      });
  };

  const handleFormDialog = (postData, entityInfo) => {
    let entity = postData.field_sections[entityInfo.sectionIndex].referenced_entities[entityInfo.entityIndex];
    let title, message;
    if (postData && entityInfo) {
      if (entity.complete && !entity.checked) {
        toggleCheckbox(entity, entity.checked);
      }
      else {
        title = `${entity.bundle === "quiz" ? "Quiz" : "Form"} not submitted`
        message = `Please complete the ${entity.bundle === "quiz" ? "quiz" : "form"} before confirming`;
        setCheckboxLoading({})
        setViewMessageTitle(title);
        setViewMessage(message);
        setShowView(true);
      }
    }
  }

  const renderPostImage = (post) => {
    const img = post.field_featured_image;

    if (img) {
      return <PostImage imgSrc={img} nid={post.nid} />;
    }
  };

  const linkBuilder = (entity) => {
    let link;
    if (entity.type_id === "node") {
      if (entity.bundle === "documents" || entity.bundle === "document") {
        link = `${serverUrl}${entity.uri}`;
      }

      if (entity.bundle === "videos" || entity.bundle === "video") {
        link = `${window.location.origin}/videos/${entity.id}`;
      }

      if (entity.bundle === 'webform') {
        link = `${window.location.origin}/forms/${entity.id}`;
      }

      if (entity.bundle === "quiz") {
        link = `${window.location.origin}/quizzes/${entity.id}`;
      }

      if (entity.bundle === "external_resource" || entity.bundle === "link") {
        if (entity.entity?.field_url) {
          link = entity.entity?.field_url[0]?.uri;
        }
        else if (entity.entity?.field_website) {
          link = entity.entity?.field_website[0]?.uri;
        }
        else {
          link = null
        }
      }
    }

    return link;
  };

  const toggleOpened = (entity) => {
    const data = {
      nid: post.nid,
      id: entity.id,
      contentType: entity.bundle,
    };

    patchNode(
      `/api/training/progress/${user.current_user.uid}`,
      data,
      user.csrf_token
    )
      .then((response) => {
        setLoading(true);
        getContent(params.id);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const toggleCheckbox = (entity, checked) => {
    const data = {
      nid: post.nid,
      id: entity.id,
      contentType: entity.bundle,
      checked: checked ? "false" : "true",
    };

    patchNode(
      `/api/training/progress/${user.current_user.uid}`,
      data,
      user.csrf_token
    )
      .then((response) => {
        getContent(params.id);
      })
      .catch((error) => {
        setLoading(false);
        setCheckboxLoading({});
      });
  };

  const submitCourse = () => {
    let entities = [];

    post.field_sections.forEach((section) => {
      section.referenced_entities.forEach((_entity) => {
        entities.push({
          target_id: _entity.id,
        });
      });
    });

    const data = {
      _links: {
        type: {
          href: serverUrl + "/rest/type/node/course_submission",
        },
      },
      type: {
        target_id: "course_submission",
      },
      title: {
        value: post.title + " Submission",
      },
      field_entity_reference: entities,
      field_course: {
        0: {
          target_id: post.nid,
        },
      },
    };

    postNode("node", data, user.csrf_token)
      .then((response) => {
        if (response.status === 201) {
          setSubmitLoading(false);
          setSubmitError(false);
          setLoading(true);
          getContent(params.id);

        } else {
          // error
          setSubmitLoading(false);
          setSubmitError(true);
        }
      })
      .catch((error) => {
        setSubmitLoading(false);
        setSubmitError(true);
      });
  };

  const quizOrWebForm = (entityBundle) => {
    if ((entityBundle === "quiz") || (entityBundle === "webform")) {
      return true;
    }
    else {
      return false
    }
  };

  if (isLoading) {
    return <SkeletonSingleScreen />;
  } else {
    if (isError) {
      return <Error status={errorStatus} message={errorMessage} />;
    } else {
      let category = "Uncategorised";

      if(post.category_labels){
        category = post.category_labels
      }

      return (
        <main className="training single">
          <article className="article">
            <SinglesHeader
              route={"/training"}
              backBtnText={"Courses and Training"}
              category={category}
              timestamp={post.created}
              title={post.title}
            />
            {renderPostImage(post)}
            <div className="article__body">
              <PostBody body={post.body} />
            </div>
            {post.submitted && (
              <Alert
                className="ps-4"
                owner="client"
                severity="success"
                sx={{
                  "& .MuiAlert-icon": {
                    padding: "7px 0px 7px 9px"
                  }
                }}
              >
                <Typography
                  owner="client"
                  variant="body1"
                  weight="light"
                >
                  You have completed the course - but you can still view the resources below
                </Typography>
              </Alert>
            )}

            {post.field_sections && post.field_sections.length > 0 && (
              <>
                <hr />
                {post.field_sections.map((section, sectionIndex) => {
                  return (
                    <Row key={`section-${sectionIndex}`}>
                      <Col xs={12} className="section-title">
                        <Typography
                          className={`section-header mb-1`}
                          owner="client"
                          variant="h5"
                          color="clientHeading.trainingSection"
                        >
                          {section.field_section_title}
                        </Typography>
                      </Col>
                      <Col xs={12} className="section-body mt-2 mb-2">
                        <Typography
                          owner="client"
                          variant="body1"
                          color="clientText.main"
                          weight="light"
                          lh="wide"
                        >
                          {section.field_section_body}
                        </Typography>
                      </Col>
                      <Col xs={12}>
                        {section.referenced_entities &&
                          section.referenced_entities.length > 0 && (
                            <>
                              {section.referenced_entities.map(
                                (entity, index) => {
                                  return (
                                    <Row
                                      key={`section-${sectionIndex}-entity-${index}`}
                                      className="justify-center align-center align-items-center gx-0"
                                    >
                                      <Col xs="auto" className="ps-4">
                                        {post.submitted || (quizOrWebForm(entity.bundle) && entity.checked) ?
                                          <PostCheckBox
                                            courseEntity={true}
                                            disabled={true}
                                            checked={true}
                                            onChange={() => { }}
                                          />
                                          :
                                          checkboxLoading.hasOwnProperty(`${entity.id}`) ?
                                            <Box
                                              className="d-flex justify-content-center align-items-center"
                                              sx={{
                                                padding: "12px"
                                              }}
                                            >
                                              <CircularProgress
                                                size={14}
                                              />
                                            </Box>
                                            :
                                            <PostCheckBox
                                              courseEntity={true}
                                              checked={entity.checked}
                                              disabled={(
                                                Object.keys(checkboxLoading) > 1) ||
                                                ((quizOrWebForm(entity.bundle)) ?
                                                  entity.checked :
                                                  false
                                                )
                                              }
                                              onChange={(e) => {
                                                if (quizOrWebForm(entity.bundle)) {
                                                  setCheckboxLoading({ ...checkboxLoading, [entity.id]: true });
                                                  getContent(params.id, { sectionIndex: sectionIndex, entityIndex: index });
                                                }
                                                else if (!entity.complete) {
                                                  let message = "";
                                                  let title = ""

                                                  title = "Item incomplete"
                                                  message = "Please view the item before confirming.";
                                                  setViewMessageTitle(title);
                                                  setViewMessage(message);
                                                  setShowView(true);
                                                } else {
                                                  setCheckboxLoading({ ...checkboxLoading, [entity.id]: true });
                                                  toggleCheckbox(entity, entity.checked);
                                                }
                                              }}
                                            />
                                        }
                                      </Col>
                                      <Col xs="auto" className="flex-grow-1">
                                        <Button
                                          className="w-100 d-flex justify-content-between px-0"
                                          variant="trainingItemBtn"
                                          disableRipple={true}
                                          disableFocusRipple={true}
                                          endIcon={<ArrowForwardIcon />}
                                          onClick={() => {
                                            const link = linkBuilder(entity);
                                            if (link && link.length > 1) {
                                              window.open(link, "_blank");
                                              toggleOpened(entity);
                                            } else {
                                              setResourceUnavailable(true);
                                              setResourceType(entity.bundle);
                                            }
                                            // if (entity.bundle === 'webform') {
                                            //   // modal
                                            //   setFormId(entity.id);
                                            //   setFormDialog(true);
                                            // } else {
                                            //   const link = linkBuilder(entity);
                                            //   if (link === null) {
                                            //     setResourceUnavailable(true);
                                            //     setResourceType(entity.bundle);
                                            //   }
                                            //   else if (link.length > 1) {
                                            //     window.open(link, "_blank");
                                            //     toggleOpened(entity);
                                            //   }
                                            // }
                                          }}
                                        >
                                          <Typography
                                            owner="client"
                                            variant="body1"
                                          >
                                            {entity.title}
                                          </Typography>
                                        </Button>
                                      </Col>
                                    </Row>
                                  );
                                }
                              )}
                            </>
                          )}
                      </Col>
                      <Col xs={12}>
                        <hr className="my-4" />
                      </Col>
                    </Row>
                  );
                })}
                {!post.field_hide_submission && !post.submitted && (
                  <>
                    <Row className="section-body mb-2">
                      <Col xs={12}>
                        <Typography
                          className={`section-header mb-1`}
                          owner="client"
                          variant="h5"
                          color="clientHeading.trainingSection"
                        >
                          {post.signTitle
                            ? decodeHtmlEntities(post.signTitle)
                            : alertMessages.courseSignOff}
                        </Typography>
                      </Col>
                    </Row>
                    <Row className="gx-0">
                      <Col xs={12} className="section-body mt-2 mb-2">
                        <Typography
                          owner="client"
                          variant="body1"
                          color="clientText.main"
                          weight="light"
                        >
                          {post.field_confirmation_prompt
                            ? post.field_confirmation_prompt
                            : alertMessages.courseSignOffPrompt}
                        </Typography>
                      </Col>
                      <Col xs={12} className="section-body mt-2 mb-2">
                        <Row className="align-items-center gx-0">
                          <Col xs="auto" className="ps-4">
                            <PostCheckBox
                              courseEntity={true}
                              checked={confirmed}
                              // disabled={!entity.complete}
                              onChange={(e) => {
                                const entityCompleteLength =
                                  Object.keys(checkBoxes).length;
                                if (entityCompleteLength !== itemLength) {
                                  setCourseIncomplete(true);
                                } else {
                                  setConfirmed(!confirmed);
                                }
                              }}
                            />
                          </Col>
                          <Col xs="auto" className="flex-grow-1">
                            <Typography
                              owner="client"
                              variant="body1"
                              color="clientText.main"
                              weight="light"
                            >
                              {post.field_confirmation_text
                                ? post.field_confirmation_text
                                : alertMessages.courseSignOffConfirmation}
                            </Typography>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mt-5 justify-content-end">
                      <Col xs="auto">
                        <Button
                          variant="contained"
                          endIcon={!submitLoading && <ArrowForwardIcon />}
                          disabled={!confirmed || submitLoading}
                          color="clientPrimary"
                          onClick={() => {
                            setSubmitLoading(true);
                            submitCourse();
                          }}
                        >
                          {submitLoading ? (
                            <CircularProgress size={14} />
                          ) : (
                            <Typography owner="client" variant="body1">
                              Submit
                            </Typography>
                          )}
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}

            <Row className="sections"></Row>
          </article>

          <AlertModal
            warningIcon
            showAlert={showView}
            alertMessageTitle={viewMessageTitle}
            alertMessageBody={viewMessage}
            confirmButton={true}
            confirmButtonLabel={"Ok"}
            onConfirm={() => {
              setShowView(false);
              setViewMessageTitle("");
              setViewMessage("");
            }}
          />
          <AlertModal
            showAlert={courseIncomplete}
            warningIcon
            alertMessageTitle={"Course incomplete"}
            alertMessageBody={"Please review and mark all items before submitting."}
            confirmButton={true}
            confirmButtonLabel={"Ok"}
            onConfirm={() => {
              setCourseIncomplete(false);
              setViewMessageTitle("");
              setViewMessage("");
            }}
          />
          <AlertModal
            warningIcon
            showAlert={submitError}
            alertMessageTitle={"Submission failed"}
            alertMessageBody={"Failed to submit course. Please try again later."}
            confirmButton={true}
            confirmButtonLabel={"Ok"}
            onConfirm={() => {
              setSubmitError(false);
            }}
          />
          <AlertModal
            warningIcon
            showAlert={resourceUnavailable}
            alertMessageTitle={`${resourceType.charAt(0).toUpperCase() + resourceType.slice(1)} unavailable`}
            alertMessageBody={"Resource is temporarily unavailable"}
            confirmButton={true}
            confirmButtonLabel={"Ok"}
            onConfirm={() => {
              setResourceUnavailable(false);
            }}
          />
          <IframeDialog
            open={formDialog}
            onClose={() => {
              setFormId("");
              setFormDialog(false);
            }}
            title={""}
            src={`${serverUrl}/node/${formId}?app=true`}
          />
        </main>
      );
    }
  }
}
