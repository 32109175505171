import React from "react";

// Modules
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";

// App
import { advancedHeaderMenu } from "../config";
import { auth_logout, get_logout_token, get_csrf_token } from "../core/auth";
import { getOnlineNode } from "../core/getNode";
import { patchNode } from "../core/postNode";
import { alertMessages } from "../partials/alertMessages";
import AlertModal from "../partials/alertModal";
import MediaLibrary from "./mediaLibrary";
import UserImage from "./userImage";
import Notification from "./notificationModal";
import { searchEnabled } from "../config";
import { useWindowSize } from "./windowSize";

// UI components
import { AppLogo, notificationsEnabled } from "../config";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {
  ChevronDown,
  Menu as MenuIcon,
  Search,
  User,
  // Menu,
  X,
} from "react-feather";
import {
  Avatar,
  Badge,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItem,
  Typography,
  TextField,
  InputAdornment
} from "@mui/material";
import { Container } from "react-bootstrap";
import { ReactComponent as AccountIcon } from "../assets/account-icon.svg";
import { ReactComponent as NotificationIcon } from "../assets/notification-icon.svg";
import { ReactComponent as MediaLibraryIcon } from "../assets/media-library-icon.svg";
import { ReactComponent as LogoutIcon } from "../assets/logout-icon.svg";
import { ReactComponent as LaptopIcon } from "../assets/laptop-icon.svg";
import { ReactComponent as AddInIcon } from "../assets/addin-icon.svg";
import { ReactComponent as TrainingIcon } from "../assets/training-icon.svg";
import SearchIcon from '@mui/icons-material/SearchOutlined';
import ClearIcon from '@mui/icons-material/ClearOutlined';

export default function Header(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isAccountLoading, setIsAccountLoading] = useState(true);
  const [isAccountError, setIsAccountError] = useState(false);
  const [accountErrorStatus, setAccountErrorStatus] = useState("");
  const [accountErrorMessage, setAccountErrorMessage] = useState("");
  const [_user, _setUser] = useState({});
  const [csrf_token, setCsrfToken] = useState("");
  const [logout_token, setLogout_token] = useState("");
  const [search, setSearch] = useState("");
  const [userMenu, setUserMenu] = useState(false);
  const [userMenuAnchor, setUserMenuAnchor] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [activeItem, setActiveItem] = useState(0);
  const [scrolled, setScrolled] = useState("");
  const [searchModal, setSearchModal] = useState(false);
  const [showMediaLibrary, setShowMediaLibrary] = useState(false);
  const [mediaType, setMediaType] = useState("");
  const [noticeLoadingArr, setNoticeLoadingArr] = useState([]);
  const [noticeReminderInterval, setNoticeReminderInterval] = useState(60);
  const [alertType, setAlertType] = useState("");
  const [alertTitle, setAlertTitle] = useState("");
  const [alertBody, setAlertBody] = useState("");
  const [alertConfirmButton, setAlertConfirmButton] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  const user = useSelector((state) => state).authReducer.user;
  const showHeader = useSelector((state) => state).headerReducer.showHeader;
  const notifications = useSelector((state) => state).noticeReducer;
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useWindowSize();

  useEffect(() => {
    getToken();
    window.addEventListener("scroll", () => handleScroll());
    return () => {
      window.removeEventListener("scroll", () => handleScroll());
    };
  }, []);

  useEffect(() => {
    getAccount();
  }, [user]);

  const setAccount = (_data) => {
    setIsAccountLoading(false);
    _setUser(_data);
  };

  const getToken = () => {
    get_csrf_token().then((response) => {
      setCsrfToken(response.data);
    });
  };

  const getAccount = () => {
    if (typeof user.current_user === "undefined") {
      //
      setIsAccountLoading(false);
    } else {
      getOnlineNode("custom_user/" + user.current_user.uid)
        .then((response) => {
          setAccount(response.data.rows[0]);
        })
        .catch((_error) => { });
    }
  };

  const getTokens = () => {
    if (user.current_user) {
      get_logout_token()
        .then((get_logout_token_response) => {
          get_csrf_token()
            .then((get_xcsrf_token_response) => {
              setLogout_token(get_logout_token_response.data.logout_token);
              setCsrfToken(get_xcsrf_token_response.data);
              logout();
            })
            .catch((_error) => {
              if (_error.response) {
                if (_error.response.status === 403) {
                  _setUser("");
                  dispatch({ type: "REMOVE_SESSION" });
                } else {
                  setModalVisible(true);
                  setAlertType("error");
                  setAlertTitle(alertMessages.unkownError.title);
                  setAlertBody(_error.response.data);
                  setAlertConfirmButton(true);
                }
              } else if (_error.request) {
                setModalVisible(true);
                setAlertType("error");
                setAlertTitle(alertMessages.serverNoResponse.title);
                setAlertBody(alertMessages.serverNoResponse.message);
                setAlertConfirmButton(true);
              } else {
                setModalVisible(true);
                setAlertType("error");
                setAlertTitle(alertMessages.unkownError.title);
                setAlertBody(alertMessages.unkownError.message);
                setAlertConfirmButton(true);
              }
            });
        })
        .catch((_error) => {
          if (_error.response) {
            if (_error.response.status === 403) {
              _setUser("");
              dispatch({ type: "REMOVE_SESSION" });
            } else {
              setModalVisible(true);
              setAlertType("error");
              setAlertTitle(alertMessages.unkownError.title);
              setAlertBody(_error.response.data);
              setAlertConfirmButton(true);
            }
          } else if (_error.request) {
            setModalVisible(true);
            setAlertType("error");
            setAlertTitle(alertMessages.serverNoResponse.title);
            setAlertBody(alertMessages.serverNoResponse.message);
            setAlertConfirmButton(true);
          } else {
            setModalVisible(true);
            setAlertType("error");
            setAlertTitle(alertMessages.unkownError.title);
            setAlertBody(alertMessages.unkownError.message);
            setAlertConfirmButton(true);
          }
        });
    }
  };

  const logout = () => {
    if (!logout_token && !csrf_token) {
      setModalVisible(true);
      setAlertType("error");
      setAlertTitle(alertMessages.noTokens.title);
      setAlertBody(alertMessages.noTokens.message);
      setAlertConfirmButton(true);

      getTokens();

      return false;
    }

    auth_logout(logout_token, csrf_token)
      .then((_response) => {
        _setUser("");
        dispatch({ type: "REMOVE_SESSION" });
      })
      .catch((_error) => {
        if (_error.response) {
          if (_error.response.status === 403) {
            _setUser("");
            dispatch({ type: "REMOVE_SESSION" });
          } else {
            setModalVisible(true);
            setAlertType("error");
            setAlertTitle(alertMessages.unkownError.title);
            setAlertBody(_error.response.data);
            setAlertConfirmButton(true);
          }
        } else if (_error.request) {
          setModalVisible(true);
          setAlertType("error");
          setAlertTitle(alertMessages.serverNoResponse.title);
          setAlertBody(alertMessages.serverNoResponse.message);
          setAlertConfirmButton(true);
        } else {
          setModalVisible(true);
          setAlertType("error");
          setAlertTitle(alertMessages.unkownError.title);
          setAlertBody(alertMessages.unkownError.message);
          setAlertConfirmButton(true);
        }
      });
  };

  const toggleActiveItem = (eventKey) => {
    setActiveItem(eventKey);
  };

  const handleuserMenuClose = () => {
    setUserMenu(false);
    setUserMenuAnchor(null);
  };

  const renderUser = () => {
    if (user.current_user) {
      const firstName = _user.field_first_name;
      const lastName = _user.field_last_name;
      const userPicture = _user.user_picture;
      let username;

      if (firstName && lastName) {
        username = firstName + " " + lastName;
      } else {
        username = _user.name;
      }

      if (user.current_user) {
        return (
          <>
            <Button
              id="user-dropdown-button"
              sx={{
                textTransform: "none",
                color: "text.secondary",
              }}
              onClick={(e) => {
                let userMenuTemp = userMenu;
                setUserMenu(!userMenuTemp);
                setUserMenuAnchor(
                  document.querySelector("#user-dropdown-button")
                );

                switch (location.pathname) {
                  case "/account":
                    toggleActiveItem(1);
                    break;
                  case "/account/messages":
                    toggleActiveItem(2);
                    break;
                  case "/account/notifications":
                    toggleActiveItem(3);
                    break;
                  default:
                    toggleActiveItem(0);
                }

                if (location.pathname.indexOf("/account/messages") > -1) {
                  toggleActiveItem(2);
                }

                if (location.pathname.indexOf("edit-notice") > -1) {
                  toggleActiveItem(3);
                }
              }}
              startIcon={
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                  <UserImage
                    name={`${firstName} ${lastName}`}
                    image={userPicture}
                    clientColor="adminPrimary.main"
                    sx={{
                      borderColor: "clientPrimary",
                    }}
                  />
                </Badge>
              }
            >
              <Typography
                className="user__name  d-none d-md-block"
                variant="span"
                owner="client"
                color="clientText.main"
              >
                {username}
              </Typography>
              <ChevronDown className="user__toggle  d-none d-sm-block" />
            </Button>
            <Menu
              anchorEl={userMenuAnchor}
              open={userMenu}
              onClose={handleuserMenuClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                className={activeItem === 1 ? "active" : ""}
                selected={activeItem === 1}
                variant="userDropdown"
                onClick={() => {
                  navigate("/account");
                  handleuserMenuClose();
                }}
              >
                <ListItemIcon>
                  <AccountIcon style={{ width: "24px", height: "auto" }} />
                </ListItemIcon>
                Account
              </MenuItem>
              {advancedHeaderMenu && (
                <MenuItem
                  className={activeItem === 4 ? "active" : ""}
                  selected={activeItem === 4}
                  variant="userDropdown"
                  onClick={() => {
                    navigate("/account/notifications");
                    handleuserMenuClose();
                  }}
                >
                  <ListItemIcon>
                    <NotificationIcon
                      style={{ width: "24px", height: "auto" }}
                    />
                  </ListItemIcon>
                  Notices
                </MenuItem>
              )}
              {advancedHeaderMenu && (
                <MenuItem
                  className={activeItem === 4 ? "active" : ""}
                  selected={activeItem === 4}
                  variant="userDropdown"
                  onClick={() => {
                    navigate("/account/add-in");
                    handleuserMenuClose();
                  }}
                >
                  <ListItemIcon>
                    <AddInIcon style={{ width: "24px", height: "auto" }} />
                  </ListItemIcon>
                  Outlook Add-in
                </MenuItem>
              )}
              {advancedHeaderMenu && (
                <MenuItem
                  className={activeItem === 4 ? "active" : ""}
                  selected={activeItem === 4}
                  variant="userDropdown"
                  onClick={() => {
                    navigate("/account/laptop-app");
                    handleuserMenuClose();
                  }}
                >
                  <ListItemIcon>
                    <LaptopIcon style={{ width: "24px", height: "auto" }} />
                  </ListItemIcon>
                  Laptop App
                </MenuItem>
              )}
              {advancedHeaderMenu && (
                <MenuItem
                  className={activeItem === 4 ? "active" : ""}
                  selected={activeItem === 4}
                  variant="userDropdown"
                  onClick={() => {
                    navigate("/account/training");
                    handleuserMenuClose();
                  }}
                >
                  <ListItemIcon>
                    <TrainingIcon style={{ width: "24px", height: "auto" }} />
                  </ListItemIcon>
                  Training
                </MenuItem>
              )}
              {!checkRole() && (
                <MenuItem
                  className={activeItem === 3 ? "active" : ""}
                  variant="userDropdown"
                  onClick={() => {
                    setShowMediaLibrary(true);
                    handleuserMenuClose();
                  }}
                >
                  <ListItemIcon>
                    <MediaLibraryIcon
                      style={{ width: "24px", height: "auto" }}
                    />
                  </ListItemIcon>
                  Media Library
                </MenuItem>
              )}

              <Divider />
              <MenuItem
                variant="userDropdown"
                onClick={() => {
                  getTokens();
                  handleuserMenuClose();
                }}
              >
                <ListItemIcon>
                  <LogoutIcon style={{ width: "24px", height: "auto" }} />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>

            {showMediaLibrary && (
              <MediaLibrary
                user={user}
                csrf_token={csrf_token}
                mediaType={mediaType}
                showMediaLibrary={showMediaLibrary}
                isNotForInsert={true}
                setMediaType={(type) => {
                  setMediaType(type);
                }}
                setShowMediaLibrary={(condition) => {
                  setShowMediaLibrary(condition);
                }}
              />
            )}
          </>
        );
      } else {
        return (
          <Link to={"/login"} className="login-button">
            <User size={20} />
            <span>Login</span>
          </Link>
        );
      }
    } else {
      return (
        <Link to={"/login"} className="login-button">
          <User size={20} />
          <span>Login</span>
        </Link>
      );
    }
  };

  const submitSearchForm = async (event) => {
    if (search.length < 1) {
      event.preventDefault();
    } else {
      event.preventDefault();
      navigate("/search?q=" + search);
    }
  };

  const clearSearchForm = async () => {
    setSearch("");
    //await this.props.saveSearchTerm('')

    // Only redirect to search page when the form is cleared if on search page
    if (location === "/search") {
      navigate("/search");
    } else {
      return false;
    }
  };

  const handleScroll = () => {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      setScrolled("scrolled");
    } else {
      setScrolled("");
    }
  };

  /**
   * Set modal visibility
   * @param {*} visible
   */
  const _setModalVisible = (visible) => {
    setModalVisible(visible);
  };

  const _setSearchModal = () => {
    let searchModalTemp = searchModal;
    setSearchModal(!searchModalTemp);
  };

  const checkRole = () => {
    if (_user?.roles_target_id) {
      let rolesArr = _user?.roles_target_id?.split(",");
      if (rolesArr.length > 1) {
        return false;
      } else {
        if (rolesArr.find((el) => el === "RHG viewer")) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const addLoading = (id) => {
    let loadingArray = noticeLoadingArr;
    if (!loadingArray.includes(id)) {
      loadingArray.push(id);
      setNoticeLoadingArr(loadingArray);
    }
  };

  const removeLoading = (id) => {
    let loadingArray = noticeLoadingArr;
    let index = loadingArray.indexOf(id);
    if (index > -1) {
      loadingArray.splice(index, 1);
      setNoticeLoadingArr(loadingArray);
    }
  };

  // Toggle single notification status
  const toggleNotification = (id, status) => {
    let path = `api/notifications/read?id=${id}`;
    if (status == false) {
      path = path + "&unread=true";
    }
    addLoading(id);
    patchNode(`${path}&_format=hal_json`, {}, user.csrf_token, true)
      .then((response) => {
        if (response.status == 201 || response.status == 200) {
          removeLoading(id);
          dispatch({
            type: "TOGGLE_READ",
            payload: { id: id, status: status },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        removeLoading(id);
      });
  };

  // update next reminder time
  const forceNextReminder = () => {
    patchNode(
      `api/notifications/forced?_format=hal_json`,
      {},
      user.csrf_token,
      true
    )
      .then((response) => {
        getNotifications();
      })
      .catch((error) => {
        console.log("forced response", error);
      });
  };

  // Mark all notifications as read
  const markAllNotifications = () => {
    let path = `api/notifications/read?all=true`;
    patchNode(`${path}&_format=hal_json`, {}, user.csrf_token, true)
      .then((response) => {
        if (response.status == 201 || response.status == 200) {
          dispatch({ type: "MARK_ALL_NOTICES" });
          forceNextReminder();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Remove read notifications from popup
  const removeNotifications = () => {
    let tempRows = notifications.rows.filter((e, i) => {
      if (!e.isRead) {
        return e;
      }
    });
    dispatch({ type: "MODIFY_ROWS", payload: { rows: tempRows } });
  };

  const getNotifications = () => {
    getOnlineNode(`api/notifications?_format=json`)
    // getOnlineNode(`api/notifications?debug&_format=json`)
      .then((response) => {
        dispatch({ type: 'SAVE_NOTIFICATIONS', payload: response.data })
      })
      .catch((error) => {
        console.log('notifications error', error)
      });
  }

  const searchField = (_dialog) => {
    return (
      <TextField
        className="search-input"
        sub_variant="search"
        owner="client"
        autoFocus={true}
        value={search}
        onChange={(e) => {
          setSearch(e.target.value)
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter" && search.trim() !== "") {
            e.preventDefault();
            if (_dialog) {
              setShowSearch(false);
            }
            navigate(`search/${search}`);
          }
        }}
        InputProps={{
          endAdornment:
            <>
              <InputAdornment position="start">
                <IconButton
                  className="navbar-btn p-0"
                  color="clientPrimary"
                  onClick={() => {
                    if (search.trim() !== "") {
                      if (_dialog) {
                        setShowSearch(false);
                      }
                      navigate(`search/${search}`);
                    }
                    else {
                      setShowSearch(!showSearch);
                    }
                  }}
                >
                  <SearchIcon sx={{ fontSize: "1.2rem" }} />
                </IconButton>
              </InputAdornment>
            </>
        }}
      />
    );
  }

  const renderSearch = () => {
    return (
      <Row className="align-items-center">
        <Col xs="auto" className="d-flex align-items-center">
          {searchField()}
        </Col>
      </Row >
    )
  }

  const searchDialog = () => {
    return (
      <>
        <Col xs="auto" className="">
          <IconButton
            className="navbar-btn"
            color="clientPrimary"
            onClick={(e) => {
              setShowSearch(true);
              e.target.blur();
            }}
          >
            <SearchIcon sx={{ fontSize: "1.2rem" }} />
          </IconButton>
        </Col>
        <Dialog
          open={showSearch && (width < 842)}
          onClose={() => setShowSearch(false)}
          className="search-modal"
        >
          <DialogTitle>
            <Col
              xs={"auto"}
              className="d-flex align-items-center justify-content-center"
            >
              {searchField(true)}
            </Col>
          </DialogTitle>
        </Dialog>
      </>
    );
  }


  return (
    <>
      <header className={`header show-${showHeader} ${scrolled}`}>
        <Container fluid>
          <Row className="align-items-center  justify-content-between">
            <Col xs="auto" className="hmg-container">

              {/* <div className="hmg-container me-0"> */}
              <IconButton
                onClick={() => {
                  props.sidebarVisibility();
                }}
                className="navbar-btn"
                color="clientPrimary"
              >
                <MenuIcon />
              </IconButton>
              {/* </div> */}
            </Col>
            <Col xs="auto">
              {(searchEnabled && (width > 842)) && renderSearch()}
              {(searchEnabled && (width <= 842)) &&  searchDialog()}
            </Col>
            {/* <Col xs={"auto"} className="d-none d-sm-block justify-content-start search-container ps-0">
                <form className="search ms-0" onSubmit={this.submitSearchForm}>
                  <div className="flex-grow-1">
                    <input
                      type="text"
                      placeholder="Search"
                      value={search}
                      onChange={(event) => {
                        this.setState({
                          search: event.target.value,
                        });
                      }}
                    />
                  </div>
                  {search.length > 0 && (
                    <IconButton
                      type="button"
                      onClick={() => this.clearSearchForm()}
                    >
                      <X />
                    </IconButton>
                  )}
                  <IconButton type="submit">
                    <Search />
                  </IconButton>
                </form>
              </Col> */}
            <div
              className={`branding-container ${scrolled} ${props.navbarState} ${location.pathname.search("presentations-creator") > -1 ||
                location.pathname.search("edit") > -1 ||
                location.pathname.search("preview") > -1
                ? "presentation-variant"
                : ""
                }`}
            >
              <Link to="/" className="branding">
                <AppLogo />
              </Link>
            </div>
            <Col xs={"auto"} className="flex-grow-1">
              <Row className="justify-content-end  align-items-center">
                <Col xs={"auto"}>{renderUser()}</Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </header>

      <AlertModal
        showAlert={modalVisible}
        showAlertCallback={() => _setModalVisible()}
        alertType={alertType}
        alertMessageTitle={alertTitle}
        alertMessageBody={alertBody}
        confirmButton={alertConfirmButton}
      />

      {notificationsEnabled && (
        <Notification
          showPath={"/presentations-creator"}
          currentPath={location.pathname}
          notifications={notifications.rows}
          settings={notifications.settings}
          statusChange={(id, status) => {
            toggleNotification(id, status);
          }}
          forceNextReminder={() => {
            forceNextReminder();
          }}
          loadingArray={noticeLoadingArr}
          markAllNotifications={() => {
            markAllNotifications();
          }}
        />
      )}
    </>
  );
}
