import React, { useState, useRef, useEffect } from "react";

// Modules

// App

// UI components
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  Button,
  FormControlLabel,
  Checkbox,
  Tooltip,
  IconButton,
  Box,
  Typography,
} from "@mui/material";
import { PlayArrow, Settings, KeyboardBackspace } from "@mui/icons-material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

export default function Toolbar(props) {
  const backTooltipTarget = useRef(null);
  const viewTooltipTarget = useRef(null);
  const settingsTooltipTarget = useRef(null);
  const logoTooltipTarget = useRef(null);
  const backgroundTooltipTarget = useRef(null);
  const transitionTooltipTarget = useRef(null);
  const deleteTooltipTarget = useRef(null);
  const notesTooltipTarget = useRef(null);

  const [index, setIndex] = useState(0);
  const [slide, setSlide] = useState(null);
  const [isLibrarySlide, setLibrarySlide] = useState(false);
  const [isCorrupt, setCorrupt] = useState(false);

  useEffect(() => {
    setIndex(props.indexh);
  }, [props.indexh]);

  useEffect(() => {
    if (props.presentation && props.presentation?.slides?.length > 0) {
      props.presentation.slides.forEach((slide, slideIndex) => {
        if (index === slideIndex) {
          setSlide(slide);

          if (slide.initial_presentation !== props.presentation.id) {
            setLibrarySlide(true);
          } else {
            setLibrarySlide(false);
          }
        }
      });
    }
  }, [index, props.presentation]);

  useEffect(() => {
    if (props.presentation && props.presentation?.slides?.length > 0) {
      props.presentation.slides.forEach((slide, slideIndex) => {
        if (index === slideIndex) {
          setSlide(slide);

          if (slide.initial_presentation !== props.presentation.id) {
            setLibrarySlide(true);
          } else {
            setLibrarySlide(false);
          }
        }
      });
    }
  }, [index, props.presentation]);

  useEffect(() => {
    if (slide?.id) {
      if (props.corruptSlides.length) {
        if (props.corruptSlides.includes(slide.id)) {
          setCorrupt(true);
        } else {
          setCorrupt(false);
        }
      }
    }
  }, [slide, props.corruptSlides]);

  return (
    <div className="ps-toolbar">
      <Container fluid>
        <Row className="align-items-center toolbar-row first-row">
          <Col sm={12} md={6} className="ls">
            <Row className="align-items-center">
              <Col xs="auto">
                <Tooltip
                  id="back-tooltip"
                  title="Back to presentations"
                  placement="bottom"
                >
                  <IconButton
                    ref={backTooltipTarget}
                    variant="circular"
                    owner="admin"
                    className="d-flex align-items-center"
                    onClick={() => {
                      props.goBack();
                    }}
                    color="white"
                  >
                    <KeyboardBackspace />
                  </IconButton>
                </Tooltip>
              </Col>
              <Col xs="auto">
                <Tooltip
                  id="settings"
                  title="Presentation settings"
                  placement="bottom"
                >
                  <IconButton
                    ref={settingsTooltipTarget}
                    variant="circular"
                    owner="admin"
                    className="d-flex align-items-center"
                    onClick={() => {
                      props.onPresentationModal();
                    }}
                    color="white"
                  >
                    <Settings />
                  </IconButton>
                </Tooltip>
              </Col>
              <Col xs="auto">
                {props?.presentation?.slides?.length > 0 && (
                  <div className="d-flex align-items-center">
                    <Button
                      variant="circular"
                      owner="admin"
                      ref={viewTooltipTarget}
                      className="preview-btn d-flex align-items-center"
                      // className='d-flex align-items-center'
                      onClick={() => {
                        window.open(
                          `/presentations/preview/${props?.presentation?.id}`,
                          "_blank"
                        );
                      }}
                      startIcon={<PlayArrow fontSize={"small"} />}
                    >
                      <Typography variant="subtitle2" owner="admin">
                        Preview
                      </Typography>
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={6} className="rs">
            <Row className="align-items-center justify-content-end">
              {!isLibrarySlide && !isCorrupt && (
                <Col xs="auto">
                  <Box className="bx checkbox">
                    <FormControlLabel
                      labelPlacement="end"
                      className="d-flex align-items-center"
                      control={
                        <Checkbox
                          size="small"
                          checked={slide?.published === true ? false : true}
                          sx={{
                            "& .MuiFormControlLabel-label": {
                              fontSize: "0.75rem",
                              fontWeight: 500,
                              color: "#777",
                            },
                          }}
                          onChange={(event) => {
                            props.toggleStatus([slide.id]);
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="subtitle2"
                          owner="admin"
                          color="adminText.dark"
                        >
                          Hide
                        </Typography>
                      }
                    />
                  </Box>
                </Col>
              )}
              {!isCorrupt && (
                <Col xs="auto">
                  <Box className="bx">
                    <Tooltip
                      id="delete-tooltip"
                      title="Delete slide"
                      placement="bottom"
                    >
                      <Button
                        ref={deleteTooltipTarget}
                        variant="toolbar-btn"
                        sub_variant="error"
                        owner="admin"
                        onClick={() => {
                          props.onRemoveSlides([slide.id]);
                        }}
                        startIcon={<DeleteOutlineIcon fontSize={"small"} />}
                      >
                        <Typography
                          variant="subtitle2"
                          owner="admin"
                          className="btn-lh"
                        >
                          Delete
                        </Typography>
                      </Button>
                    </Tooltip>
                  </Box>
                </Col>
              )}
            </Row>
          </Col>
        </Row>

        {!isCorrupt && (
          <Row className="align-items-center toolbar-row second-row">
            <Col sm={12} md={6} className="ls">
              <Typography
                className="ps-title"
                variant="h6"
                owner="admin"
                color="adminText.dark"
              >
                {props?.presentation?.title} (#{props?.presentation?.id})
              </Typography>
            </Col>
            <Col sm={12} md={6} className="rs">
              {!isLibrarySlide && (
                <Row className="align-items-center justify-content-end">
                  <Col xs="auto">
                    <Box className="bx">
                      <Tooltip
                        id="slide-settings-tooltip"
                        title="Modify slide settings"
                        placement="bottom"
                      >
                        <Button
                          variant="toolbar-btn"
                          sub_variant="primary"
                          owner="admin"
                          onClick={() => {
                            props.onSlideSettings();
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            owner="admin"
                            className="btn-lh"
                          >
                            Settings
                          </Typography>
                        </Button>
                      </Tooltip>
                    </Box>
                  </Col>

                  <Col xs="auto">
                    <Box className="bx">
                      <Tooltip
                        id="background-tooltip"
                        title="Change slide background"
                        placement="bottom"
                      >
                        <Button
                          ref={backgroundTooltipTarget}
                          variant="toolbar-btn"
                          sub_variant="primary"
                          owner="admin"
                          onClick={() => {
                            props.onBackgroundSettings();
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            owner="admin"
                            className="btn-lh"
                          >
                            Background
                          </Typography>
                        </Button>
                      </Tooltip>
                    </Box>
                  </Col>

                  <Col xs="auto">
                    <Box className="bx">
                      <Tooltip
                        id="logo-tooltip"
                        title="Add logo to slide"
                        placement="bottom"
                      >
                        <Button
                          ref={logoTooltipTarget}
                          variant="toolbar-btn"
                          sub_variant="primary"
                          owner="admin"
                          onClick={() => {
                            props.onLogoSettings();
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            owner="admin"
                            className="btn-lh"
                          >
                            Logo
                          </Typography>
                        </Button>
                      </Tooltip>
                    </Box>
                  </Col>

                  <Col xs="auto">
                    <Box className="bx">
                      <Tooltip
                        id="transition-tooltip"
                        title="Change slide transition"
                        placement="bottom"
                      >
                        <Button
                          ref={transitionTooltipTarget}
                          variant="toolbar-btn"
                          sub_variant="primary"
                          owner="admin"
                          onClick={() => {
                            props.onTransitionSettings();
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            owner="admin"
                            className="btn-lh"
                          >
                            Transitions
                          </Typography>
                        </Button>
                      </Tooltip>
                    </Box>
                  </Col>
                  {/* <Col xs="auto">
                    <Box className="bx">
                      <Tooltip
                        id="notes-tooltip"
                        title="Add slide notes"
                        placement="bottom"
                      >
                        <Button
                          ref={notesTooltipTarget}
                          variant="toolbar-btn"
                          sub_variant="primary"
                          owner="admin"
                          onClick={() => {
                            props.onSlideNotes();
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            owner="admin"
                            className="btn-lh"
                          >
                            Notes
                          </Typography>
                        </Button>
                      </Tooltip>
                    </Box>
                  </Col> */}
                </Row>
              )}
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}
