import { formatTime } from "../utils/helpers";

import { Link as RouterLink } from 'react-router-dom';

import PostTitle from "./postTitle";
import { Row, Col } from "react-bootstrap";
import { Typography, Chip } from "@mui/material";
import { ArrowBack } from '@mui/icons-material';
import Link from '@mui/material/Link';
import ClientTheme from "../core/clientTheme";
import TagIcon from '@mui/icons-material/LocalOfferOutlined';


export default function SinglesHeader(props) {
  const clientTheme = { ...ClientTheme() }
  let chipVariant = clientTheme.components?.chip?.variant ? clientTheme.components?.chip?.variant : "filled"

  return (
    <header className="article__header">
      <div className="article__meta">
        <Row className='justify-content-between'>
          <Col className="d-flex align-items-center" xs={"auto"}>
            <Link
              component={RouterLink}
              to={props.route}
              className="d-flex align-items-center"
              owner="client"
              hoverbehavior={clientTheme.components.link.hoverbehavior}
            >
              <ArrowBack className="me-1" sx={{ fontSize: "1rem" }} />
              <Typography
                className="d-none d-sm-block"
                owner="client"
                variant="h6"
                weight="regular"
                lh="wide"
              >
                {props.backBtnText}
              </Typography>
            </Link>
          </Col>
          <Col xs={10} sm={7}>
            <Row className="justify-content-end">
              <Col xs={7} className="px-0">
                <Row className="justify-content-end">
                  <Col xs={"auto"}>
                    <Chip
                      className='category-chip'
                      variant="default"
                      owner="client"
                      role="metaData"
                      label={
                        <Typography
                          owner="client"
                          variant="body2"
                          weight="medium"
                        >
                          {clientTheme.components.chip.icon &&
                            < TagIcon className="me-1" sx={{ fontSize: "0.8rem" }} />
                          }
                          {props.category}
                        </Typography>
                      } />
                  </Col>
                </Row>
              </Col>
              {props.timestamp &&
                <Col className="d-flex align-items-center"
                  xs={"auto"}
                >
                  <Typography
                    owner="client"
                    variant="subtitle2"
                    color={clientTheme.color.heading.screens ? "clientHeadingScreens.main" : "clientText.main"}
                  >
                    {formatTime(props.timestamp)}
                  </Typography>
                </Col>
              }
            </Row>
          </Col>
        </Row>
      </div>
      {props.title &&
        <PostTitle
          title={props.title}
          owner="client"
          titleColor="clientHeading.items"
          headingLevel={'h1'}
        />
      }
    </header>
  );
}