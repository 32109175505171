import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import { getOnlineNode } from '../../core/getNode'

import PageHeader from '../../partials/pageHeader'
import NoticeItem from './notificationItem'
import Pagination from '../../partials/pagination'
import NoData from '../../partials/noData';
import ListHeader from '../../partials/listHeader'
import Creator from '../../partials/creator'
import { getFavoritesByContentType } from '../../utils/helpers'
import { favoritesEnabled } from '../../config'

import { Row, Col } from 'react-bootstrap'
import CompanyappLoader from '../../partials/companyappLoader'


export default function Notifications(props) {
  // const notifications = useSelector((state) => state).noticeReducer.rows
  const [isLoading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([])
  const user = useSelector((state) => state).authReducer.user;
  const noticeRows = useSelector((state) => state).noticeReducer.rows;
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 20;

  const favorites = useSelector((state) => state).favoritesReducer;
  const contentType = "notices"
  const favStrings = getFavoritesByContentType(favorites, contentType, true);

  const listLabels = [
    { title: "ID", width: { xs: 1 }, colClass: "ps-4" },
    { title: "Date", width: { md: 1 }, colClass: "d-none d-md-block me-4" },
    { title: "Subject", width: { xs: 1, md: "auto" }, grow: 1 },
    { title: "Read", width: { xs: 2, sm: 1 }, align: "center", colClass: "d-flex justify-content-center" },
    { title: "Fav.", width: { xs: 1 }, align: "center", colClass: "d-flex justify-content-center" },
    { title: "Open", width: { xs: 2, sm: 1 }, align: "center", colClass: "d-flex justify-content-center" },
  ]

  useEffect(() => {
    if ((activeIndex === 1 && favStrings !== "") || activeIndex === 0) {
      getNotices(1);
    }
    else {
      setNotifications([]);
      setLoading(false);
    }
  }, [activeIndex]);

  
  useEffect(() => {
    if (activeIndex === 1) {
      if (favStrings !== "") {
        getNotices(1);
      }
      else {
        setNotifications([]);
        setLoading(false);
      }
    }
  }, [favStrings]);


  const getNotices = (_currentPage) => {
    let path = `api/notifications?all=true&items_per_page=${pageSize}&page=${_currentPage - 1}&_format=json`

    if (activeIndex === 1) {
      path = `api/notifications?ids=${favStrings}&all=true&items_per_page=${pageSize}&page=${_currentPage - 1}&_format=json`
    }

    if (user.current_user) {
      getOnlineNode(path)
        .then((response) => {
          setNotifications(response.data.rows);
          setLoading(false);
        })
        .catch((error) => {
          console.log('notifications archive error', error)
        })
    }
  }

  const onPaginate = (_index) => {
    setLoading(true);
    setCurrentPage(_index);
    getNotices(_index);
  };


  return (
    <>
      <div className='notifications screen d-flex flex-column justify-content-between h-100 mx-lg-auto'>
        <Row>
          <Col>
            <PageHeader
              pageName="Notices"
              enableFavorites={true}
              owner="admin"
              getFavourites={() => {
                setActiveIndex(activeIndex === 0 ? 1 : 0);
                setLoading(true);
              }}
              activeTabIndex={activeIndex}
            />
            <ListHeader
              items={listLabels}
              labelConfig={{
                owner: "admin",
                variant: "subtitle1",
                color: "adminPrimary.main",
                weight: "medium",
                lh: "wide"
              }}
            />
            <div className='notifications-list'>
              {isLoading ?
                <CompanyappLoader />
                :
                notifications.length > 0 ?
                  <>
                    {notifications.map((e) => {
                      return <NoticeItem item={e} user={user} rows={noticeRows} contentType={contentType} />
                    })}
                  </>
                  :
                  <NoData activeIndex={activeIndex} />
              }
            </div>
            {(!isLoading && notifications.length > 0) && (
              <Pagination
                containerClasses={`d-flex justify-content-center align-items-center mt-5`}
                previousDisabled={currentPage === 1 ? true : false}
                nextDisabled={notifications.length < pageSize ? true : false}
                currentPage={currentPage}
                onPrevious={() => {
                  onPaginate(currentPage - 1);
                }}
                onNext={() => {
                  onPaginate(currentPage + 1);
                }}
              />
            )}
          </Col>
        </Row>
        <Creator />
      </div>
    </>
  )
}