import { Checkbox } from "@mui/material";
import CheckboxIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import CheckboxIconChecked from "@mui/icons-material/CheckBoxOutlined";

export default function PostCheckBox(props) {
  if (props.courseEntity) {
    return (
      <Checkbox
        variant="postCheckBox"
        checked={props.checked}
        disabled={props.disabled}
        onChange={(event) => {
          if (props.onChange) {
            props.onChange(event)
          }
        }}
        icon={
          <CheckboxIcon className="UncheckedIcon" sx={{ fontSize: "1rem" }} />
        }
        checkedIcon={
          <CheckboxIconChecked
            className="CheckedIcon"
            sx={{ fontSize: "1rem" }}
          />
        }
      />
    );
  } else {
    return (
      <Checkbox
        variant="postCheckBox"
        // checked={props.checked}
        // disabled={props.disabled}
        // onChange={(event) => {
        //   if (props.onChange) {
        //     props.onChange(event)
        //   }
        // }}
        icon={
          <CheckboxIcon
            className="UncheckedIcon"
            sx={{ fontSize: "1rem" }}
          />
        }
        checkedIcon={
          <CheckboxIconChecked
            className="CheckedIcon"
            sx={{ fontSize: "1rem" }}
          />
        }
      />
    );
  }
}
