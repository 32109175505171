import React, { useEffect, useState } from 'react';

// Modules
import moment from 'moment';

// App
import MediaLibrary from '../mediaLibrary';

// UI components
import { Row, Col } from 'react-bootstrap';
import { Button } from '@mui/material';

export default function SlideAudioPicker(props) {
  const [showMediaLibrary, setShowMediaLibrary] = useState(false);
  const [mediaType, setMediaType] = useState('audio');
  const [slideAudio, setSlideAudio] = useState(props.slideAudio ? props.slideAudio : '');
  const [isAudioSelected, setIsAudioSelected] = useState(false);

  useEffect(() => {
    if (props.slideAudio) {
      setIsAudioSelected(true);
    } else {
      setIsAudioSelected(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='settings-wrap audio-select'>
      <Row className='mb-3'>
        <Col xs='auto' className='d-flex align-items-center'>
          <h5 className='m-0'>{props.title}</h5>
        </Col>
      </Row>
      <Row>
        <div>
          <div className='main-wrapper upload-audio'>
            {isAudioSelected ?
              (<Row className='audio-selected-container'>
                <Col xs={12} lg={6} className='d-flex justify-content-center align-items-center'>
                  <audio
                    controls
                    src={slideAudio.field_media_audio}
                  >
                    <source type="audio/mpeg" />
                  </audio>
                </Col>
                <Col xs={12} lg={6}>
                  <Row className='my-3 justify-content-end justify-content-lg-start'>
                    <Col xs={3} className='property'>
                      <span>File name:</span>
                    </Col>
                    <Col xs={6} lg={'auto'} className='value name'>
                      <span>{slideAudio.name}</span>
                    </Col>
                  </Row>
                  <Row className='my-3 justify-content-end justify-content-lg-start'>
                    <Col xs={3} className='property'>
                      <span>MID:</span>
                    </Col>
                    <Col xs={6} lg={'auto'} className='value'>
                      <span>{slideAudio.mid}</span>
                    </Col>
                  </Row>
                  <Row className='my-3 justify-content-end justify-content-lg-start'>
                    <Col xs={3} className='property'>
                      <span>Created:</span>
                    </Col>
                    <Col xs={6} lg={'auto'} className='value'>
                      <span>{moment.unix(slideAudio.created).format("DD[.]MM[.]YYYY [(]hh[:]mm[)]")}</span>
                    </Col>
                  </Row>
                  <Row className='my-3 justify-content-end justify-content-lg-start'>
                    <Col xs={3} className='property'>
                      <span>File size:</span>
                    </Col>
                    <Col xs={6} lg={'auto'} className='value'>
                      <span>45mb</span>
                    </Col>
                  </Row>
                  <Row className='mt-xl-5 btn-grp justify-content-center justify-content-lg-start'>
                    <Col xs={'auto'}>
                      <Button className='change-btn px-2'
                        onClick={() => {
                          setShowMediaLibrary(true);
                        }}
                      >Change audio</Button>
                    </Col>
                    <Col xs={'auto'}>
                      <Button className='remove-btn px-2'
                        onClick={() => {
                          setSlideAudio('');
                          setIsAudioSelected(false);
                          props.onSlideAudioSelected('');
                        }}>Remove Audio
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>)
              :
              (<Row className='bi-uyo'>
                <Col className='d-flex justify-content-center flex-column'>
                  <Row className='hint'>
                    <Col className='d-flex justify-content-center text-center'>
                      <div>To attach a sound to the slide, please select or<br />upload an audio file via the media library</div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='d-flex justify-content-center text-center'>
                      <Button className='upload-btn'
                        onClick={() => {
                          setShowMediaLibrary(true);
                        }}>
                        Media library
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>)}
          </div>
        </div>
      </Row>

      {showMediaLibrary && (
        <MediaLibrary
          user={props.user}
          presentation={props.presentation}
          slide={props.slide}
          mediaType={mediaType}
          setMediaType={(type => {
            setMediaType(type);
          })}
          setShowMediaLibrary={setShowMediaLibrary}
          showMediaLibrary={showMediaLibrary}
          onMediaInsert={(media) => {
            setSlideAudio(media);
            props.onSlideAudioSelected(media);
          }}
        />
      )}
    </div>
  );
}